
const images = [
	{
		id: 1,
		src: "first1.jpeg",
		alt: "Image 1",
	},
	{
		id: 2,
		src: "second2.jpeg",
		alt: "Image 2 ",
	},
	{
		id: 3,
		src: "third3.jpeg",
		alt: "Image 3",
	},
];
export default images;
