// Import necessary components and styles
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentComponent from '../razorpay/payment';
import './model.css';
// Modal component
function MyModal(props) {
  const dataToDisplay=props.dataToDisplay;
  // console.log(dataToDisplay,"  : ok");
  const bookId = props.bookId;
  const userId = props.userId;
  const classes=props.classes;
  const bookpage=props?.bookpage
  const title=props?.title
  console.log(classes);
  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{paddingLeft: '43%'}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          
   {dataToDisplay}
        </p>
      </Modal.Body>
      <Modal.Footer style={{display: 'flex',
    justifyContent: 'center'}}>
      {
        bookpage==true?'':< PaymentComponent classes={classes} bookId={bookId} userId={userId} />
      }
        
      </Modal.Footer>
    </Modal>
  );
}


export default MyModal;