// import { Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import Carousel from 'react-bootstrap/Carousel';
// import Form from 'react-bootstrap/Form';
// import './login.css';
// import Button from 'react-bootstrap/Button';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { } from 'react-router-dom';
// import axios from 'axios';
// import swal from 'sweetalert2';
// import { Dropdown } from 'react-bootstrap';

// function Login() {
//   const [status, setStatus] = useState('email');
//   const [isReg, setData] = useState(true);
//   const [selectedCountry, setSelectedCountry] = useState(90);

//   // const [mobileNumber, setMobileNumber] = useState('');
//   const [countries, setCountries] = useState([]);
//   const [activeBtn, setActiveBtn] = useState('email');

 
//   const handleCountryChange = (event) => {
//     setSelectedCountry(event.target.value);
//   };
//   const handleCountryChange1 = (value) => {
//     setSelectedCountry(value);
//   };

//   const navigate = useNavigate();
//   const goToHome=(page)=>{
//       navigate(`/home?pagename=${page}`);
//   }


//   const [formData, setFormData] = useState({
//     fullName: '',
//     email: '',
//     MobileNumber: '',
//     location: ''
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };


//   const toggleBtns = (key) => {
//     setStatus(key)
//     setActiveBtn(key);
//     console.log(key);
//     setFormData({
//       email: '',
//       MobileNumber: '',

//     })

//   }
//   useEffect(() => {
//     getAllCountryCode();
//   }, [])

//   const getAllCountryCode = async () => {
// //console.log(https://www.creativekidsworld.org`,'test')
//     const resp = await axios.get('https://www.creativekidsworld.org/masterclass/getcountrycode');

//     console.log(resp.data.data,'sachin');
//     setCountries(resp.data.data);
//   }

//   const doAction = () => {
//     if (isReg == true) {
//       checkUserExistence();
//     } else {
//       doRegister();
//     }
//   }

//   const doRegister = async () => {
//     if(countries[selectedCountry]?.code){
//       console.log(`${countries[selectedCountry]?.code}${formData.MobileNumber}`);

//     }
//     try {
//       const data = formData.email !== '' ? { 'email': formData.email } : { 'MobileNumber': `${countries[selectedCountry].code}${formData.MobileNumber}` }
//       console.log(data);
//     console.log(`${countries[selectedCountry]?.code}${formData.MobileNumber}`);
//       if(countries[selectedCountry]?.code){
//         formData['MobileNumber']=`${countries[selectedCountry]?.code}${formData.MobileNumber}`;
//       }
//       const resp = await axios.post('https://www.creativekidsworld.org/user/registration', formData);
//       console.log("Vijay:  ", resp);
//       if (resp.data.status == true) {
//         // swal.fire({
//         //   title: 'Success',
//         //   text: 'Your account has been successfully created, and an OTP has been sent.',
//         //   icon: 'success',
//         // });
//         if (formData.email) {
//           navigate(`/otp-auth?email=${data.email}`);
//         } else {
//           navigate(`/otp-auth?phone=${data.MobileNumber}`);
//         }


//       }
//     } catch (exeption) {
//       console.log(exeption, "  :  Exp");
//     }
//   }

//   const checkUserExistence = async (e) => {
//     //  e.preventDefault();
//     try {
//       const data = formData.email ? { 'email': formData.email } : { 'MobileNumber': `${countries[selectedCountry]?.code}${formData.MobileNumber}` }
//       console.log(data);
//       const response = await axios.get('https://www.creativekidsworld.org/user/checkmobileoremail', {
//         params: data,
//       });
//       console.log(response);
//       if (response.data && response.data.status === true) {
//         setData(false);
//       } else {
//         // swal.fire({
//         //   title: 'Success',
//         //   text: 'OTP successfully sent.',
//         //   icon: 'success',
//         // });
//         if (formData.email) {
//           navigate(`/otp-auth?email=${data.email}`);
//         } else {
//           navigate(`/otp-auth?phone=${data.MobileNumber}`);
//         }
//       }
//     } catch (error) {
//       console.log("Err");
//     } finally {
//       // setLoading(false);
//     }
//   }


//   return (
//     <>
//     <div className='backgroungCls'>
//     <Navbar expand="lg" className="bg-body-tertiary header">
      
//       {/* <Navbar.Brand href="#home">
//           <img src='main-header-logo.jpg' height={"88px"} width={"230px"} />
//           <span className='explore'>Explore-Imagine-Create</span>
//       </Navbar.Brand> */}
//        <a href='#'>
//           <img style={{ width: '111px',paddingLeft: '7px' }} src='Logo_website.png'></img>
//         </a>
//       <Navbar.Toggle aria-controls="basic-navbar-nav" />
//       <Navbar.Collapse className='colaps' id="basic-navbar-nav">
//         <Nav className="me-auto">
//         <Nav.Link as={Link} to="/" style={{ color: 'white', marginLeft: '35px' }}>
//         Home
//       </Nav.Link>
//           {/* <Nav.Link href="#home" style={{color: 'white',marginLeft:'35px'}} to="/">Home</Nav.Link> */}
//           <Nav.Link href="https://www.myadventurebook.org/" target="_blank" style={{ color: "white", marginLeft: '35px' }}>
// Product
// </Nav.Link>

//           <NavDropdown title="Services" id="basic-nav-dropdown" style={{color:'white',marginLeft:'35px'}}>
//           <NavDropdown.Item onClick={() =>goToHome('master')}>
// Master Classes
// </NavDropdown.Item>
// <NavDropdown.Item onClick={() =>goToHome('story')}>
// Story Telling
// </NavDropdown.Item>
// <NavDropdown.Item
//               onClick={() => {
//                 navigate('/es');
//                 window.location.reload(); // Reload the page
//               }}
//             >
//               Spoken English Classes
//             </NavDropdown.Item>
//             <NavDropdown.Item
//   onClick={() => {
//     navigate('/ec');
//     window.location.reload(); // Reload the page
//   }}
//   style={{ textDecoration: 'none' }} // Remove underline
// >
//   <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
// </NavDropdown.Item>
//           </NavDropdown>
//           <Nav.Link href="https://education.ciotechoutlook.com/vendor/2023/orion_eduverse" target="_blank" style={{ color: 'white', marginLeft: '35px' }}>
// Media
// </Nav.Link>



//   <Nav.Link href="/blog" style={{color: 'white',marginLeft:'35px'}}>Blog</Nav.Link>

        
//         </Nav>
//       </Navbar.Collapse>
   
//   </Navbar>
//       {/* <Col lg={7}>
//         <Carousel data-bs-theme="dark">
//           <Carousel.Item>
//             <img
//               className="d-block" style={{ height: '590px' }}
//               src="https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg"
//               alt="First slide"
//             />


//           </Carousel.Item>
//           <Carousel.Item>
//             <img
//               className="d-block " style={{ height: '590px' }}
//               src="https://png.pngtree.com/background/20230411/original/pngtree-natural-landscape-snow-mountain-background-picture-image_2390197.jpg"
//               alt="Second slide"
//             />

//           </Carousel.Item>
//           <Carousel.Item>
//             <img
//               className="d-block " style={{ height: '590px' }}
//               src="https://t4.ftcdn.net/jpg/06/27/42/89/360_F_627428995_O1Uw9skdtStLe8bMnor5HBqksnap0mXo.jpg"
//               alt="Third slide"
//             />

//           </Carousel.Item>
//         </Carousel>
//       </Col> */}
//       {/* <Col lg={5} style={{backgroundColor: 'rgb(231, 135, 243)',
//     boxShadow: 'inset 0 0 0 2px #5e7bdf',
//     padding: '10px',height:'82vh'}}></Col> */}
//     <div
//   style={{
//     backgroundImage: 'url("/logged.png")',
//     backgroundSize: 'contain', // Ensure the entire background image is visible
//     backgroundRepeat: 'no-repeat', // Prevent the image from repeating
//     backgroundPosition: 'center', // Center the background image
//     width: '100%', // Set width to fill the container
//     height: '400px', // Set height to your desired height
//     padding: '10px'
//    // boxShadow: 'inset 0 0 0 2px #5e7bdf',
//   }}
// ></div>

//       <div  style={{width: '100%',
//         textAlign: 'center'}}>

//         <div className='forms'>
//         <Button className='login-reg-btn'>Login/Register</Button>


//           <Form className='form'>
//             <div>
//             {/* <ButtonGroup aria-label="Basic example" style={{ width: '100%', marginBottom: "22px" }}> */}
//       <Button
//         onClick={() => toggleBtns('email')}
//         className={`toggle-btn ${activeBtn === 'email' ? 'active' : ''}`}
//         variant="secondary"
//       >
//         Email
//       </Button>

//       {/* <Button
//         onClick={() => toggleBtns('MobileNumber')}
//         className={`toggle-btn ${activeBtn === 'MobileNumber' ? 'active' : ''}`}
//         variant="secondary"
//       >
//        Click Here - Mobile Number
//       </Button>
//     </ButtonGroup> */}
  
//             </div>
//             {status == 'email' ?

//               <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
//                 <Form.Label style={{fontWeight: '500'}} column sm="2">
//                   Email
//                 </Form.Label>
//                 <Col sm="10">
//                   <Form.Control value={formData.email}
//                     onChange={handleChange} name="email" type="email" placeholder="Enter your email" />
//                 </Col>
//               </Form.Group>

//               :
// <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
//   <Form.Label style={{ fontWeight: '500' }} column sm="2">
//     <span>Mobile</span> 
//   </Form.Label>
//   <Col sm="10">
//     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//     <span style={{ color: 'white', backgroundColor:'indigo', padding:'4px', marginRight: '10px', borderRadius: '4px' }}>Select Country</span>
//       <select className='form-control' style={{  height: "39px", marginRight: '10px',fontSize:'15px' }} id="countryDropdown" value={selectedCountry} onChange={handleCountryChange}>
//         <option value="" disabled>Select a country</option>
//         {countries.map((country, index) => (
//           <option key={index} value={index}>
//             {`${country?.name} (${country?.code})`}
//           </option>
//         ))}
//       </select>
//       <Dropdown onSelect={(eventKey) => handleCountryChange1(eventKey)}>
//         <Dropdown.Toggle variant="primary" id="dropdown-basic">
//         </Dropdown.Toggle>
//         <Dropdown.Menu style={{ maxHeight: '150px', overflowY: 'auto' }}>
//           {countries.map((country, index) => (
//             <Dropdown.Item key={index} eventKey={index}>
//               {`${country?.name} (${country?.code})`}
//             </Dropdown.Item>
//           ))}
//         </Dropdown.Menu>
//       </Dropdown>
//       <Form.Control style={{  height: "40px", marginRight: '10px',marginLeft:'10px',textAlign: 'center', width: '25vw',fontSize:'15px' }} value={formData.MobileNumber} onChange={handleChange} name="MobileNumber" type="tel" placeholder="Enter mobile number" />
//     </div>
//   </Col>
// </Form.Group>



                          

// /* <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
//               <Form.Label style={{fontWeight: '500'}} column sm="2">
//                 Mobile
//               </Form.Label>
//               <Col sm="10">
//                 <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
//                   <Dropdown>
//                     <Dropdown.Toggle variant="primary" id="dropdown-basic">
//                       {countries[selectedCountry]?.name || 'Select a country'}
//                     </Dropdown.Toggle>
            
//                     <Dropdown.Menu>
//                       {countries.map((country, index) => (
//                         <Dropdown.Item key={index} onSelect={() => handleCountryChange(index)}>
//                           {`${country?.name} (${country?.code})`}
//                         </Dropdown.Item>
//                       ))}
//                     </Dropdown.Menu>
//                   </Dropdown>
//                   <div>
//                     <Form.Control value={formData.MobileNumber} onChange={handleChange} name="MobileNumber" type="tel" placeholder="Enter mobile number" />
//                   </div>
//                 </div>
//               </Col>
//             </Form.Group>


// */





//             }
//             {
//               isReg ?
//                 '' : <>


//                   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
//                     <Form.Label style={{fontWeight: '500'}}  column sm="2">
//                       Name
//                     </Form.Label>
//                     <Col sm="10">
//                       <Form.Control value={formData.fullName}
//                         onChange={handleChange} name="fullName" type="text" placeholder="Enter your name" />
//                     </Col>

//                   </Form.Group>
//                   <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
//                     <Form.Label style={{fontWeight: '500'}} column sm="2">
//                       City
//                     </Form.Label>
//                     <Col sm="10">
//                       <Form.Control value={formData.location}
//                         onChange={handleChange} name="location" type="text" placeholder="Enter your city" />
//                     </Col>

//                   </Form.Group>
//                 </>
//             }

//             <Button style={{
//               marginTop: '10px',
//               width: '90px', 
//             }} type="button" eventKey={2} onClick={doAction} className='btns' variant="primary">Submit</Button>

//           </Form>
//         </div>


//       </div>
//     </div>
//     </>

 


//   )
// }

// export default Login; 



import { Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Form from 'react-bootstrap/Form';
import './login.css';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert2';

function Login() {
  const [status, setStatus] = useState('email');
  const [isReg, setData] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(null);

  // const [mobileNumber, setMobileNumber] = useState('');
  const [countries, setCountries] = useState([]);
  const [activeBtn, setActiveBtn] = useState('email');

 
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };

  const navigate = useNavigate();
  const goToHome=(page)=>{
      navigate(`/home?pagename=${page}`);
  }


  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    MobileNumber: '',
    location: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };


  const toggleBtns = (key) => {
    setStatus(key)
    setActiveBtn(key);
    console.log(key);
    setFormData({
      email: '',
      MobileNumber: '',

    })

  }
  useEffect(() => {
    getAllCountryCode();
  }, [])

  const getAllCountryCode = async () => {
//console.log(https://www.creativekidsworld.org`,'test')
    const resp = await axios.get('https://www.creativekidsworld.org/masterclass/getcountrycode');

    console.log(resp.data.data,'sachin');
    setCountries(resp.data.data);
  }

  const doAction = () => {
    if (isReg == true) {
      checkUserExistence();
    } else {
      doRegister();
    }
  }

  const doRegister = async () => {
    if(countries[selectedCountry]?.code){
      console.log(`${countries[selectedCountry]?.code}${formData.MobileNumber}`);

    }
    try {
      const data = formData.email !== '' ? { 'email': formData.email } : { 'MobileNumber': `${countries[selectedCountry].code}${formData.MobileNumber}` }
      console.log(data);
    console.log(`${countries[selectedCountry]?.code}${formData.MobileNumber}`);
      if(countries[selectedCountry]?.code){
        formData['MobileNumber']=`${countries[selectedCountry]?.code}${formData.MobileNumber}`;
      }
      const resp = await axios.post('https://www.creativekidsworld.org/user/registration', formData);
      console.log("Vijay:  ", resp);
      if (resp.data.status == true) {
        // swal.fire({
        //   title: 'Success',
        //   text: 'Your account has been successfully created, and an OTP has been sent.',
        //   icon: 'success',
        // });
        if (formData.email) {
          navigate(`/otp-auth?email=${data.email}`);
        } else {
          navigate(`/otp-auth?phone=${data.MobileNumber}`);
        }


      }
    } catch (exeption) {
      console.log(exeption, "  :  Exp");
    }
  }

  const checkUserExistence = async (e) => {
    //  e.preventDefault();
    try {
      const data = formData.email ? { 'email': formData.email } : { 'MobileNumber': `${countries[selectedCountry]?.code}${formData.MobileNumber}` }
      console.log(data);
      const response = await axios.get('https://www.creativekidsworld.org/user/checkmobileoremail', {
        params: data,
      });
      console.log(response);
      if (response.data && response.data.status === true) {
        setData(false);
      } else {
        // swal.fire({
        //   title: 'Success',
        //   text: 'OTP successfully sent.',
        //   icon: 'success',
        // });
        if (formData.email) {
          navigate(`/otp-auth?email=${data.email}`);
        } else {
          navigate(`/otp-auth?phone=${data.MobileNumber}`);
        }
      }
    } catch (error) {
      console.log("Err");
    } finally {
      // setLoading(false);
    }
  }


  return (
    <>
    <div className='backgroungCls'>
    <Navbar expand="lg" className="bg-body-tertiary header">
      
      {/* <Navbar.Brand href="#home">
          <img src='main-header-logo.jpg' height={"88px"} width={"230px"} />
          <span className='explore'>Explore-Imagine-Create</span>
      </Navbar.Brand> */}
       <a href='#'>
          <img style={{ width: '111px',paddingLeft: '7px' }} src='Logo_website.png'></img>
        </a>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className='colaps' id="basic-navbar-nav">
        <Nav className="me-auto">
        <Nav.Link as={Link} to="/" style={{ color: 'white', marginLeft: '35px' }}>
        Home
      </Nav.Link>
          {/* <Nav.Link href="#home" style={{color: 'white',marginLeft:'35px'}} to="/">Home</Nav.Link> */}
          <Nav.Link href="https://myadventurebook.org/" target="_blank" style={{ color: "white", marginLeft: '35px' }}>
Product
</Nav.Link>

          <NavDropdown title="Services" id="basic-nav-dropdown" style={{color:'white',marginLeft:'35px'}}>
          <NavDropdown.Item onClick={() =>goToHome('master')}>
Master Classes
</NavDropdown.Item>
<NavDropdown.Item onClick={() =>goToHome('story')}>
Story Telling
</NavDropdown.Item>
<NavDropdown.Item onClick={() => {
                navigate('/es');
                window.location.reload();
              }}>
                Spoken English Classes
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                navigate('/ec');
                window.location.reload();
              }} style={{ textDecoration: 'none' }}>
                <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
              </NavDropdown.Item>
              
              <NavDropdown.Item onClick={() => goToHome('innovation')} >
                
              <div style={{ color: 'red', textDecoration: 'none' }}>Innovation Classes </div>
              </NavDropdown.Item>
              
            </NavDropdown>
          <Nav.Link href="https://drive.google.com/file/u/1/d/1hEWSYOT3Yurr7pI5-vwkSCwQxa9dlwg8/view" target="_blank" style={{ color: 'white', marginLeft: '35px' }}>
Media
</Nav.Link>



  <Nav.Link href="/blog" style={{color: 'white',marginLeft:'35px'}}>Blog</Nav.Link>
  <Nav.Link href="https://mabkart.org/" target="_blank" style={{ color: "white", marginLeft: '2px' }}>
           Merchandise
            </Nav.Link>

        
        </Nav>
      </Navbar.Collapse>
   
  </Navbar>
      {/* <Col lg={7}>
        <Carousel data-bs-theme="dark">
          <Carousel.Item>
            <img
              className="d-block" style={{ height: '590px' }}
              src="https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg"
              alt="First slide"
            />


          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block " style={{ height: '590px' }}
              src="https://png.pngtree.com/background/20230411/original/pngtree-natural-landscape-snow-mountain-background-picture-image_2390197.jpg"
              alt="Second slide"
            />

          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block " style={{ height: '590px' }}
              src="https://t4.ftcdn.net/jpg/06/27/42/89/360_F_627428995_O1Uw9skdtStLe8bMnor5HBqksnap0mXo.jpg"
              alt="Third slide"
            />

          </Carousel.Item>
        </Carousel>
      </Col> */}
      {/* <Col lg={5} style={{backgroundColor: 'rgb(231, 135, 243)',
    boxShadow: 'inset 0 0 0 2px #5e7bdf',
    padding: '10px',height:'82vh'}}></Col> */}
    <div
  style={{
    backgroundImage: 'url("/logged.png")',
    backgroundSize: 'contain', // Ensure the entire background image is visible
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    backgroundPosition: 'center', // Center the background image
    width: '100%', // Set width to fill the container
    height: '400px', // Set height to your desired height
    padding: '10px'
   // boxShadow: 'inset 0 0 0 2px #5e7bdf',
  }}
></div>

      <div  style={{width: '100%',
        textAlign: 'center'}}>

        <div className='forms'>
        <Button className='login-reg-btn'>Login/Register</Button>


          <Form className='form'>
            <div>
            {/* <ButtonGroup aria-label="Basic example" style={{ width: '100%', marginBottom: "22px" }}> */}
      <Button
        onClick={() => toggleBtns('email')}
        className={`toggle-btn ${activeBtn === 'email' ? 'active' : ''}`}
        variant="secondary"
      >
        Email
      </Button>

      {/* <Button
        onClick={() => toggleBtns('MobileNumber')}
        className={`toggle-btn ${activeBtn === 'MobileNumber' ? 'active' : ''}`}
        variant="secondary"
      >
        Mobile Number
      </Button>
    </ButtonGroup> */}
  
            </div>
            {status == 'email' ?

              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label style={{fontWeight: '500'}} column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control value={formData.email}
                    onChange={handleChange} name="email" type="email" placeholder="Enter your email" />
                </Col>
              </Form.Group>

              :

              <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                <Form.Label style={{fontWeight: '500'}} column sm="2">
                  Mobile
                </Form.Label>
                <Col sm="10">
                  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>

                    <select className='form-control' style={{ width: "100px", height: "39px" }} id="countryDropdown" value={selectedCountry} onChange={handleCountryChange}>
                      <option value="" disabled>Select a country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={index}>
                          {`${country?.name} (${country?.code})`}
                        </option>
                      ))}
                    </select>
                      <div>
                   
                        <Form.Control value={formData.MobileNumber}
                          onChange={handleChange} name="MobileNumber" type="tel" placeholder="Enter mobile number" />
                      </div>
                  
                  </div>

                </Col>

              </Form.Group>

            }
            {
              isReg ?
                '' : <>


                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label style={{fontWeight: '500'}}  column sm="2">
                      Name
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control value={formData.fullName}
                        onChange={handleChange} name="fullName" type="text" placeholder="Enter your name" />
                    </Col>

                  </Form.Group>
                  <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                    <Form.Label style={{fontWeight: '500'}} column sm="2">
                      City
                    </Form.Label>
                    <Col sm="10">
                      <Form.Control value={formData.location}
                        onChange={handleChange} name="location" type="text" placeholder="Enter your city" />
                    </Col>

                  </Form.Group>
                </>
            }

            <Button style={{
              marginTop: '10px',
              width: '90px', 
            }} type="button" eventKey={2} onClick={doAction} className='btns' variant="primary">Submit</Button>

          </Form>
        </div>


      </div>
    </div>
    </>

 


  )
}

export default Login; 