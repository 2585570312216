import { Card, Col, Row } from 'react-bootstrap';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { PiFacebookLogoBold } from "react-icons/pi";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaSquareXTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa6';
import './footer.css';

function Footer(){
return(
    <Card>
    <Row className='row-setting'>
        <Col lg={8} style={{lineHeight:'2'}}>
        <img style={{width: '125px'}}  src='Logo_website.png'></img>
            
            <p>
            <FaLocationDot className="left-icons" /><span style={{paddingLeft: '10px'}}>Ranchi</span>
            </p>
           
        <p><FaPhone className="left-icons" /><span style={{paddingLeft: '10px'}}>+919380706694</span></p>
        <p>
        <MdEmail className="left-icons" /><span style={{paddingLeft: '10px'}}> creativekidsworld777@gmail.com</span>
        </p>
       
        </Col>
        <Col lg={1}>
      
        <p style={{ paddingBottom: '10px', cursor: 'pointer' }}>
  <a href="https://www.orioneduverse.com/privacypolicy" target="_blank" rel="noopener noreferrer">
    Privacy Policy
  </a>
</p>

<p style={{cursor:'pointer'}}><a href="https://www.orioneduverse.com/termsandconditions" target="_blank" rel="noopener noreferrer">
Terms & Conditions
  </a></p>
        </Col>
        <Col lg={3}>
          <div>
            {/* <PiFacebookLogoBold className="right-icon" /> */}
            {/* <FaInstagramSquare className="right-icon" /> */}
            <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none',}} target="_blank" href="https://www.instagram.com/creative_kids_world_mab/"><FaInstagram style={{ color: '#E4405F' }}/></a>
            &nbsp;&nbsp;
            <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.youtube.com/@CreativeKidsWorldforyou"><FaYoutube style={{ color: '#FF0000' }}/></a>

{/* <FaYoutubeSquare className="right-icon" /> */}
          </div>
           </Col>
    </Row>
</Card>
)

}

export default Footer;