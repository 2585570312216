
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import './firstpage.css';
// import { Button, Col, Row } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import Footer from '../footer/Footer';
// import { Link } from 'react-scroll';
// import React from "react";
// import logo from "./logo.svg";
// import "./App.css";
// import images from "./images";
// import ImageSlider from "./ImageSlider";
// //analytics
// import { useEffect } from 'react';
// import ReactGA from 'react-ga';
//   const TRACKING_ID = "G-813R2VPVBK"; // measurement_ID
//   ReactGA.initialize(TRACKING_ID);



// <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
// const FirstPage = () => {
//   useEffect(() => {
//     ReactGA.pageview(window.location.pathname);
//   }, []);
//   const navigate = useNavigate();
//   const goToHome = (page) => {
//     navigate(`/home?pagename=${page}`);
//   }
//   const goToFirst=()=>{
//     navigate('/');
//   }
//   const goToDashboard = () => {
//     navigate(`/book-status?pagename=master`);
// }

// const doLogin = () => {
//   let currentRoute = window.location.pathname;
//   console.log(currentRoute);
//   localStorage.setItem('loginstatus', JSON.stringify(currentRoute));
//   navigate('/login');
// }

// const handleClick = () => {
//   localStorage.removeItem('master-app')
//   navigate('/');
// };
// const italicStyle = {
//   fontStyle: 'italic'
// };

//   return (
//     <>
    
//       <div>

//       </div>
//       <Navbar expand="lg" className="bg-body-tertiary header">

//         <Navbar.Brand href="#home">
//           {/* <img src='Logo_website.png' height={"88px"} width={"230px"} /> */}
//           <img style={{ width: '95px',paddingLeft: '7px' }} src='Logo_website.png'></img>
//           {/* <span className='explore'>We, The Creators</span> */}
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse className='colaps' style={{ marginLeft: "11% !importent" }} id="basic-navbar-nav">
//           <Nav className="me-auto">
//           <Nav.Link href='/' style={{ color: 'white', marginLeft: '35px' }}>Home</Nav.Link>
//             <Nav.Link href="https://www.myadventurebook.org/" target="_blank" style={{ color: "white", marginLeft: '35px' }}>
//               Product
//             </Nav.Link>

//             <NavDropdown title="Services" id="basic-nav-dropdown" style={{ color: 'white', marginLeft: '35px' }}>
            
//               <NavDropdown.Item onClick={() => goToHome('story')}>
//                 Story Telling
//               </NavDropdown.Item>
//               <NavDropdown.Item onClick={() => goToHome('master')}>
//                 Master Classes
//               </NavDropdown.Item>
//               <NavDropdown.Item
            
//               onClick={() => {
//                 navigate('/es');
//                 window.location.reload(); // Reload the page
//               }}
//             >
//               Spoken English Classes
//             </NavDropdown.Item>
//             <NavDropdown.Item
//   onClick={() => {
//     navigate('/ec');
//     window.location.reload(); // Reload the page
//   }}
//   style={{ textDecoration: 'none' }} // Remove underline
// >
//   <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
// </NavDropdown.Item>



//               {/* <NavDropdown.Item onClick={() => goToHome('')}>
//               school
//               </NavDropdown.Item> */}
//             </NavDropdown>
//             <Nav.Link href="https://education.ciotechoutlook.com/vendor/2023/orion_eduverse" target="_blank" style={{ color: 'white', marginLeft: '35px' }}>
//               Media
//             </Nav.Link>
//               <Nav.Link href="/blog" style={{ color: 'white', marginLeft: '35px' }}>Blog</Nav.Link>
           

//               {
//             localStorage.getItem('master-app') ?
//               <Nav.Link onClick={goToDashboard}>Dashboard</Nav.Link> : ''
//           }
//           </Nav>
//           {!localStorage.getItem('master-app') ?
//           <Nav.Link eventKey={2} onClick={doLogin}>
//             <Button variant="warning">Login</Button>
//           </Nav.Link>
//           :
//           ''}
//         {localStorage.getItem('master-app') ?
//           <Nav.Link eventKey={2} onClick={handleClick}>
//             <Button variant="warning">Logout</Button>
//           </Nav.Link>
//           :
//           ''}
//         </Navbar.Collapse>

//       </Navbar>

//       <Row>
//         <Col lg={12}>
//           <img src="/banner.jpg" width={"100%"} alt="" />
//         </Col>
//       </Row>

//       <Row className='images'>

//         <Col lg="8" className='datas'>
          
//           <h4 style={{ marginBottom: '24px !important', padding: '10px' }}>We Help Kids become BEST VERSION of themselves by unlocking the <u style={{ ...italicStyle, color: 'purple' }}  >Power of Creativity</u></h4>
//           <p style={{width: '900px', padding: '10px',fontWeight:'bold',  fontSize: '2.5em' }}> We offer 4 programs under <span style={italicStyle}>Services</span> 
//           </p>
//           <ul>
//           <li style={{ fontWeight: 'bold',fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('story')}>
//         Storytelling & Integrated Learning
//       </li>
//       <li style={{ fontWeight: 'bold',fontSize: '1.4em',  cursor: 'pointer' }} onClick={() => goToHome('master')}>
//         MasterClasses
//       </li>
//       <li style={{ fontWeight: 'bold',fontSize: '1.4em',  cursor: 'pointer' }} onClick={() =>navigate('es')}>
//         Spoken English and Personality Development Classes
//       </li>
//       <li style={{ fontWeight: 'bold',fontSize: '1.4em',  cursor: 'pointer' }} onClick={() => navigate('ec')}>
//         Entrepreneurship Class
//       </li>
//           </ul>


//         </Col>
        
//         <Col lg="4" className='image-col'>
//           <div>
//             <div className='image-container'>
//               <img src='boyimg.jpeg' alt="First Image" height={"400px"} width={"100%"} />
//             </div>
            
            
//           </div>

//         </Col>
        
//         <div><Row className='full-size-image'>
//   <Col lg={12}>
//     <img src='/qoutes.png' alt="Full Size Image" width={"100%"} />
//   </Col>
// </Row></div>

// <div className="App">
//             <ImageSlider images={images} />
//         </div>
// <div>
// <Row className='full-size-image'>
//   <Col lg={12}>
//     <img src='/3rdpage.png' alt="Full Size Image" width={"100%"}  />
//   </Col>
// </Row>
// </div>
//       </Row>
//       <Row>
//         <Col lg={12}>
//           <Footer></Footer>
//         </Col>
//       </Row>
//     </>
//   )
// }

// export default FirstPage;


import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './firstpage.css';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Footer from '../footer/Footer';
import { Link } from 'react-scroll';
import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import images from "./images";
import ImageSlider from "./ImageSlider";
import Modal from 'react-bootstrap/Modal';
import ReactGA from 'react-ga';

const TRACKING_ID = "G-813R2VPVBK"; // measurement_ID
ReactGA.initialize(TRACKING_ID);

const FirstPage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    handleShow(); // Show the modal when the page loads
  }, []);

  const navigate = useNavigate();
  const goToHome = (page) => {
    navigate(`/home?pagename=${page}`);
  }
  const goToFirst = () => {
    navigate('/');
  }
  const goToDashboard = () => {
    navigate(`/book-status?pagename=master`);
  }

  const doLogin = () => {
    let currentRoute = window.location.pathname;
    console.log(currentRoute);
    localStorage.setItem('loginstatus', JSON.stringify(currentRoute));
    navigate('/login');
  }

  const handleClick = () => {
    localStorage.removeItem('master-app');
    navigate('/');
  };

  const italicStyle = {
    fontStyle: 'italic'
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary header">
        <Navbar.Brand href="#home">
          <img style={{ width: '95px', paddingLeft: '7px' }} src='Logo_website.png' alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='colaps' style={{ marginLeft: "11% !important" }} id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href='/' style={{ color: 'white', marginLeft: '35px' }}>Home</Nav.Link>
            <Nav.Link href="https://myadventurebook.org/" target="_blank" style={{ color: "white", marginLeft: '35px' }}>
              Product
            </Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown" style={{ color: 'white', marginLeft: '35px' }}>
              <NavDropdown.Item onClick={() => goToHome('story')}>Story Telling</NavDropdown.Item>
              <NavDropdown.Item onClick={() => goToHome('master')}>Master Classes</NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                navigate('/es');
                window.location.reload();
              }}>
                Spoken English Classes
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => {
                navigate('/ec');
                window.location.reload();
              }} style={{ textDecoration: 'none' }}>
                <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>

              </NavDropdown.Item>
                  <NavDropdown.Item onClick={() => goToHome('innovation')}>
                  <div style={{ color: 'red', textDecoration: 'none' }}>Innovation Classes </div>
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://drive.google.com/file/u/1/d/1hEWSYOT3Yurr7pI5-vwkSCwQxa9dlwg8/view" target="_blank" style={{ color: 'white', marginLeft: '35px' }}>
              Media
            </Nav.Link>
            <Nav.Link href="/blog" style={{ color: 'white', marginLeft: '35px' }}>Blog</Nav.Link>
            <Nav.Link href="https://mabkart.org/" target="_blank" style={{ color: "white", marginLeft: '2px' }}>
            Merchandise 
            </Nav.Link>
            {localStorage.getItem('master-app') ? <Nav.Link onClick={goToDashboard}>Dashboard</Nav.Link> : ''}
          </Nav>
          {!localStorage.getItem('master-app') ?
            <Nav.Link eventKey={2} onClick={doLogin}>
              <Button variant="warning">Login</Button>
            </Nav.Link>
            : ''}
          {localStorage.getItem('master-app') ?
            <Nav.Link eventKey={2} onClick={handleClick}>
              <Button variant="warning">Logout</Button>
            </Nav.Link>
            : ''}
        </Navbar.Collapse>
      </Navbar>

      <Row>
        <Col lg={12}>
          <img src="/banner.jpg" width={"100%"} alt="" />
        </Col>
      </Row>

      <Row className='images'>
        <Col lg="8" className='datas'>
          <h4 style={{ marginBottom: '24px !important', padding: '10px' }}>We Help Kids become BEST VERSION of themselves by unlocking the <u style={{ ...italicStyle, color: 'purple' }}>Power of Creativity</u></h4>
          <p style={{ width: '900px', padding: '10px', fontWeight: 'bold', fontSize: '2.5em' }}> We offer 5 programs under <span style={italicStyle}>Services</span></p>
          <ul>
            <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('story')}>
              Storytelling & Integrated Learning
            </li>
            <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('master')}>
              MasterClasses
            </li>
            <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => navigate('es')}>
              Spoken English and Personality Development Classes
            </li>
            <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => navigate('ec')}>
              Entrepreneurship Class
            </li>
            <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('innovation')}>
              Innovation Classes
            </li>
          </ul>
        </Col>
        <Col lg="4" className='image-col'>
          <div>
            <div className='image-container'>
              <img src='boyimg.jpeg' alt="First Image" height={"400px"} width={"100%"} />
            </div>
          </div>
        </Col>
        <div>
          <Row className='full-size-image'>
            <Col lg={12}>
              <img src='/qoutes.png' alt="Full Size Image" width={"100%"} />
            </Col>
          </Row>
        </div>
        <div className="App">
          <ImageSlider images={images} />
        </div>
        <div>
          <Row className='full-size-image'>
            <Col lg={12}>
              <img src='/3rdpage.png' alt="Full Size Image" width={"100%"} />
            </Col>
          </Row>
        </div>
      </Row>

       <Modal show={show} onHide={handleClose} className="custom-modal">
     <Modal.Header closeButton> 
      <Modal.Title></Modal.Title> 
    </Modal.Header> 
     <Modal.Body>
      <img src="ups.jpeg" alt="Popup" style={{ width: '100%', height: '100%' ,display: "block",margin: "0 auto"}} />
    </Modal.Body> 
    <Modal.Footer style={{ display: 'flex', justifyContent: 'center' }}>
       <Button variant="secondary" onClick={handleClose}>
        Close
      </Button> 
      {/* <Button
       className= "customButton"
        variant="primary"
        href="https://www.creativekidsworld.org/callBackDetails/download"
      >
        
      </Button>  */}
    </Modal.Footer>
  </Modal>

      <Row>
        <Col lg={12}>
          <Footer></Footer>
        </Col>
      </Row>
    </>
  );
}

export default FirstPage;


// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import './firstpage.css';
// import { Button, Col, Row } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import Footer from '../footer/Footer';
// import React, { useState, useEffect } from "react";
// import "./App.css";
// import images from "./images";
// import ImageSlider from "./ImageSlider";
// import Modal from 'react-bootstrap/Modal';
// import ReactGA from 'react-ga';

// const TRACKING_ID = "G-813R2VPVBK"; // measurement_ID
// ReactGA.initialize(TRACKING_ID);

// const FirstPage = () => {
//   useEffect(() => {
//     ReactGA.pageview(window.location.pathname);
//     handleShow(); // Show the modal when the page loads
//   }, []);

//   const navigate = useNavigate();
//   const goToHome = (page) => {
//     navigate(`/home?pagename=${page}`);
//   }
//   const goToFirst = () => {
//     navigate('/');
//   }
//   const goToDashboard = () => {
//     navigate(`/book-status?pagename=master`);
//   }

//   const doLogin = () => {
//     let currentRoute = window.location.pathname;
//     console.log(currentRoute);
//     localStorage.setItem('loginstatus', JSON.stringify(currentRoute));
//     navigate('/login');
//   }

//   const handleClick = () => {
//     localStorage.removeItem('master-app');
//     navigate('/');
//   };

//   const italicStyle = {
//     fontStyle: 'italic'
//   };

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Navbar expand="lg" className="bg-body-tertiary header">
//         <Navbar.Brand href="#home">
//           <img style={{ width: '95px', paddingLeft: '7px' }} src='Logo_website.png' alt="Logo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse className='colaps' style={{ marginLeft: "11% !important" }} id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href='/' style={{ color: 'white', marginLeft: '35px' }}>Home</Nav.Link>
//             <Nav.Link href="https://www.myadventurebook.org/" target="_blank" style={{ color: "white", marginLeft: '35px' }}>
//               Product
//             </Nav.Link>
//             <NavDropdown title="Services" id="basic-nav-dropdown" style={{ color: 'white', marginLeft: '35px' }}>
//               <NavDropdown.Item onClick={() => goToHome('story')}>Story Telling</NavDropdown.Item>
//               <NavDropdown.Item onClick={() => goToHome('master')}>Master Classes</NavDropdown.Item>
//               <NavDropdown.Item onClick={() => {
//                 navigate('/es');
//                 window.location.reload();
//               }}>
//                 Spoken English Classes
//               </NavDropdown.Item>
//               <NavDropdown.Item onClick={() => {
//                 navigate('/ec');
//                 window.location.reload();
//               }} style={{ textDecoration: 'none' }}>
//                 <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
//               </NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Link href="https://education.ciotechoutlook.com/vendor/2023/orion_eduverse" target="_blank" style={{ color: 'white', marginLeft: '35px' }}>
//               Media
//             </Nav.Link>
//             <Nav.Link href="/blog" style={{ color: 'white', marginLeft: '35px' }}>Blog</Nav.Link>
//             {localStorage.getItem('master-app') ? <Nav.Link onClick={goToDashboard}>Dashboard</Nav.Link> : ''}
//           </Nav>
//           {!localStorage.getItem('master-app') ?
//             <Nav.Link eventKey={2} onClick={doLogin}>
//               <Button variant="warning">Login</Button>
//             </Nav.Link>
//             : ''}
//           {localStorage.getItem('master-app') ?
//             <Nav.Link eventKey={2} onClick={handleClick}>
//               <Button variant="warning">Logout</Button>
//             </Nav.Link>
//             : ''}
//         </Navbar.Collapse>
//       </Navbar>

//       <Row>
//         <Col lg={12}>
//           <img src="/banner.jpg" width={"100%"} alt="" />
//         </Col>
//       </Row>

//       <Row className='images'>
//         <Col lg="8" className='datas'>
//           <h4 style={{ marginBottom: '24px !important', padding: '10px' }}>We Help Kids become BEST VERSION of themselves by unlocking the <u style={{ ...italicStyle, color: 'purple' }}>Power of Creativity</u></h4>
//           <p style={{ width: '900px', padding: '10px', fontWeight: 'bold', fontSize: '2.5em' }}> We offer 4 programs under <span style={italicStyle}>Services</span></p>
//           <ul>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('story')}>
//               Storytelling & Integrated Learning
//             </li>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('master')}>
//               MasterClasses
//             </li>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => navigate('es')}>
//               Spoken English and Personality Development Classes
//             </li>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => navigate('ec')}>
//               Entrepreneurship Class
//             </li>
//           </ul>
//         </Col>
//         <Col lg="4" className='image-col'>
//           <div>
//             <div className='image-container'>
//               <img src='boyimg.jpeg' alt="First Image" height={"400px"} width={"100%"} />
//             </div>
//           </div>
//         </Col>
//         <div>
//           <Row className='full-size-image'>
//             <Col lg={12}>
//               <img src='/qoutes.png' alt="Full Size Image" width={"100%"} />
//             </Col>
//           </Row>
//         </div>
//         <div className="App">
//           <ImageSlider images={images} />
//         </div>
//         <div>
//           <Row className='full-size-image'>
//             <Col lg={12}>
//               <img src='/3rdpage.png' alt="Full Size Image" width={"100%"} />
//             </Col>
//           </Row>
//         </div>
//       </Row>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Popup Image</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
//           <img src='popups.jpeg' alt="Popup" style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }} />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" href="https://www.creativekidsworld.org/callBackDetails/download">
//             Download
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Row>
//         <Col lg={12}>
//           <Footer></Footer>
//         </Col>
//       </Row>
//     </>
//   );
// }

// export default FirstPage;



// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import './firstpage.css';
// import { Button, Col, Row } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import Footer from '../footer/Footer';
// import React, { useState, useEffect } from "react";
// import "./App.css";
// import images from "./images";
// import ImageSlider from "./ImageSlider";
// import Modal from 'react-bootstrap/Modal';
// import ReactGA from 'react-ga';

// const TRACKING_ID = "G-813R2VPVBK"; // measurement_ID
// ReactGA.initialize(TRACKING_ID);

// const FirstPage = () => {
//   useEffect(() => {
//     ReactGA.pageview(window.location.pathname);
//     handleShow(); // Show the modal when the page loads
//   }, []);

//   const navigate = useNavigate();
//   const goToHome = (page) => {
//     navigate(`/home?pagename=${page}`);
//   }
//   const goToFirst = () => {
//     navigate('/');
//   }
//   const goToDashboard = () => {
//     navigate(`/book-status?pagename=master`);
//   }

//   const doLogin = () => {
//     let currentRoute = window.location.pathname;
//     console.log(currentRoute);
//     localStorage.setItem('loginstatus', JSON.stringify(currentRoute));
//     navigate('/login');
//   }

//   const handleClick = () => {
//     localStorage.removeItem('master-app');
//     navigate('/');
//   };

//   const italicStyle = {
//     fontStyle: 'italic'
//   };

//   const [show, setShow] = useState(false);

//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   return (
//     <>
//       <Navbar expand="lg" className="bg-body-tertiary header">
//         <Navbar.Brand href="#home">
//           <img style={{ width: '95px', paddingLeft: '7px' }} src='Logo_website.png' alt="Logo" />
//         </Navbar.Brand>
//         <Navbar.Toggle aria-controls="basic-navbar-nav" />
//         <Navbar.Collapse className='colaps' style={{ marginLeft: "11% !important" }} id="basic-navbar-nav">
//           <Nav className="me-auto">
//             <Nav.Link href='/' style={{ color: 'white', marginLeft: '35px' }}>Home</Nav.Link>
//             <Nav.Link href="https://www.myadventurebook.org/" target="_blank" style={{ color: "white", marginLeft: '35px' }}>
//               Product
//             </Nav.Link>
//             <NavDropdown title="Services" id="basic-nav-dropdown" style={{ color: 'white', marginLeft: '35px' }}>
//               <NavDropdown.Item onClick={() => goToHome('story')}>Story Telling</NavDropdown.Item>
//               <NavDropdown.Item onClick={() => goToHome('master')}>Master Classes</NavDropdown.Item>
//               <NavDropdown.Item onClick={() => {
//                 navigate('/es');
//                 window.location.reload();
//               }}>
//                 Spoken English Classes
//               </NavDropdown.Item>
//               <NavDropdown.Item onClick={() => {
//                 navigate('/ec');
//                 window.location.reload();
//               }} style={{ textDecoration: 'none' }}>
//                 <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
//               </NavDropdown.Item>
//             </NavDropdown>
//             <Nav.Link href="https://education.ciotechoutlook.com/vendor/2023/orion_eduverse" target="_blank" style={{ color: 'white', marginLeft: '35px' }}>
//               Media
//             </Nav.Link>
//             <Nav.Link href="/blog" style={{ color: 'white', marginLeft: '35px' }}>Blog</Nav.Link>
//             {localStorage.getItem('master-app') ? <Nav.Link onClick={goToDashboard}>Dashboard</Nav.Link> : ''}
//           </Nav>
//           {!localStorage.getItem('master-app') ?
//             <Nav.Link eventKey={2} onClick={doLogin}>
//               <Button variant="warning">Login</Button>
//             </Nav.Link>
//             : ''}
//           {localStorage.getItem('master-app') ?
//             <Nav.Link eventKey={2} onClick={handleClick}>
//               <Button variant="warning">Logout</Button>
//             </Nav.Link>
//             : ''}
//         </Navbar.Collapse>
//       </Navbar>

//       <Row>
//         <Col lg={12}>
//           <img src="/banner.jpg" width={"100%"} alt="" />
//         </Col>
//       </Row>

//       <Row className='images'>
//         <Col lg="8" className='datas'>
//           <h4 style={{ marginBottom: '24px !important', padding: '10px' }}>We Help Kids become BEST VERSION of themselves by unlocking the <u style={{ ...italicStyle, color: 'purple' }}>Power of Creativity</u></h4>
//           <p style={{ width: '900px', padding: '10px', fontWeight: 'bold', fontSize: '2.5em' }}> We offer 4 programs under <span style={italicStyle}>Services</span></p>
//           <ul>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('story')}>
//               Storytelling & Integrated Learning
//             </li>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => goToHome('master')}>
//               MasterClasses
//             </li>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => navigate('es')}>
//               Spoken English and Personality Development Classes
//             </li>
//             <li style={{ fontWeight: 'bold', fontSize: '1.4em', cursor: 'pointer' }} onClick={() => navigate('ec')}>
//               Entrepreneurship Class
//             </li>
//           </ul>
//         </Col>
//         <Col lg="4" className='image-col'>
//           <div>
//             <div className='image-container'>
//               <img src='boyimg.jpeg' alt="First Image" height={"400px"} width={"100%"} />
//             </div>
//           </div>
//         </Col>
//         <div>
//           <Row className='full-size-image'>
//             <Col lg={12}>
//               <img src='/qoutes.png' alt="Full Size Image" width={"100%"} />
//             </Col>
//           </Row>
//         </div>
//         <div className="App">
//           <ImageSlider images={images} />
//         </div>
//         <div>
//           <Row className='full-size-image'>
//             <Col lg={12}>
//               <img src='/3rdpage.png' alt="Full Size Image" width={"100%"} />
//             </Col>
//           </Row>
//         </div>
//       </Row>

//       <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
//         <Modal.Header closeButton>
//           <Modal.Title>Popup Image</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
//           <img src='popups.jpeg' alt="Popup" style={{ maxWidth: '100%', maxHeight: '80vh', objectFit: 'contain' }} />
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant="primary" href="https://www.creativekidsworld.org/callBackDetails/download">
//             Download
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       <Row>
//         <Col lg={12}>
//           <Footer></Footer>
//         </Col>
//       </Row>
//     </>
//   );
// }

// export default FirstPage;
