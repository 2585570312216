import './story.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import MyModal from './myModal';
import PaymentComponent from '../razorpay/payment';
import { useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';
import Header from '../header/Header';


function Story() {

  const [allData, setAllData] = useState(null);
  const [error, setError] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [next10Days, setNext10Days] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log('Param: ', queryParams.get('id'))
  const userId = queryParams.get('id');

  const userMeta=JSON.parse(localStorage.getItem('master-app'));



  const handleBookButtonClick = (classId) => {
    setModalShow((prevModalShow) => ({
      ...prevModalShow,
      [classId]: true,
    }));
  };

  const handleModalHide = (classId) => {
    setModalShow((prevModalShow) => ({
      ...prevModalShow,
      [classId]: false,
    }));
  };

  const scrollToElement = (id) => {

    setTimeout(() => {
      const element = document.getElementById(id);
      // console.log(element);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 500);
  };
  useEffect(() => {
    const currentDate = new Date();
    const newNext10Days = Array.from({ length: 7 }, (_, index) => {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + index++);
      return nextDate.toISOString().split('T')[0];
    });

    setNext10Days(newNext10Days);
    const cDate = new Date();
    const day1 = cDate.getDate().toString().padStart(2, '0');
    const month1 = (cDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
    const year1 = cDate.getFullYear();
    const defaultdate = `${day1}-${month1}-${year1}`;

    scrollToElement(defaultdate);
    // handleButtonClick(defaultdate);
    getAllDatas();
  }, []);

  const getAllDatas = async () => {
    try {

      const resp = await axios.get('https://www.creativekidsworld.org/storyTelling/getallclass');
      console.log(resp.data['data']);

      setAllData(resp.data['data']);

    } catch (error) {
      setError(error);
    }
  }

  const handleButtonClick = async (d) => {
    // console.log(d);
    scrollToElement(d);
  };

  const dates = next10Days.map((date, i) => {
    const currentDate = new Date(date);
    const options = { month: 'short' };
    const threeCharDate = currentDate.toLocaleDateString('en-US', options);
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
    const year = currentDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return (
      <React.Fragment key={i}>
        <div className='cols' >
          <p>{new Date(date).toLocaleDateString('en-US', { weekday: 'short' })}</p>
          <Link to={formattedDate} smooth={true} offset={-100} duration={500}>
            <h5 onClick={() => handleButtonClick(formattedDate)}>{currentDate.getDate()}</h5>
          </Link>
          <p>{threeCharDate}</p>
        </div>
      </React.Fragment>
    );
  });

  return (
    <>
    <Header pagename='storypage' />
      <div className="masterClass_banar_content text-center" style={{ width: '600px', marginBottom: '60px', marginLeft: '26%' }}>
        <h1 className='clndr'>Story Telling</h1>
        <p style={{ fontSize: '15px' }}>Discover a range of classes designed to help your child discover and learn. You can select individual Classes or get the pass to experience amazing benefits.</p>
      </div>
      <div className=''>
        <Card style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '10px', marginBottom: '40px' }}>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='rows'>
                  {dates}
                </div>
              </Col>
            </Row>
          </Container>
        </Card>
        <Container>

          <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>All Stories</h1>

          {allData?.map((classItem) => (
            <Row style={{ marginBottom: '70px' }}>

              <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{moment(moment(classItem.date, 'DD-MM-YYYY').format('MM-DD-YYYY')).format('LL')}</h2>
              <div style={{ display: 'flex' }}>
                {classItem.classDetail?.map((item) => (
                  <Col lg={4} key={classItem.id} style={{ marginBottom: "100px" }}>
                    <div className='flip-ard' id={classItem.date} >
                      <Card key={item.id} className='flip-card-inner' style={{ width: '18rem' }}>
                        <div className='flip-card-front'></div>
                        <Card.Img variant="top" src={item.image} height={'200px'} width={"200px"} />
                        <Card.Body>
                          <Card.Title className='capitalize-first-letter' style={{ fontSize: '30px' }}>{item.title}</Card.Title>
                          <Card.Text style={{ fontSize: '18px' }}>{item.timeSlot}</Card.Text>
                          <div className='desc'>
                            {item.description}

                          </div>
                          <a style={{ cursor: 'pointer' }} onClick={() => handleBookButtonClick(item._id)} className="see-more-button">
                            See More
                          </a>
                          <div>
                            < PaymentComponent
                              classes={item}
                              bookId={item._id} userId={userMeta?.data} />
                            <div>


                            </div>
                            <MyModal dataToDisplay={item.description} bookId={item._id} userId={userId}
                             classes={item}
                              show={modalShow && modalShow[item._id]} onHide={() => handleModalHide(item._id)} />
                          </div>
                        </Card.Body>
                        <div style={{ overflow: 'scroll' }} className="flip-card-back">

                          <p>{item.description}</p>
                          <Button className='toggle-btn btnsji' variant="primary"></Button>
                        </div>
                      </Card>
                    </div>
                  </Col>
                ))}
              </div>

            </Row>
          ))}
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default Story;
