// Import necessary components and styles
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import PaymentComponent from '../razorpay/payment';
// Modal component
function MyModal(props) {
  const dataToDisplay=props.dataToDisplay;
  // console.log(dataToDisplay,"  : ok");
  const bookId = props.bookId;
  const userId = props.userId;
  const classes=props.classes;

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        {/* <Modal.Title>Modal Heading</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <p>
          
   {dataToDisplay}
        </p>
      </Modal.Body>
      <Modal.Footer style={{display: 'flex',
    justifyContent: 'center'}}>
        < PaymentComponent classes={classes} bookId={bookId} userId={userId} />
        {/* Additional buttons can be added here */}
      </Modal.Footer>
    </Modal>
  );
}


export default MyModal;