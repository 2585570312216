// PaymentComponent.js
import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const PaymentComponent = ({ bookId, userId,classes }) => {
  const [orderId, setOrderId] = useState('');
  // console.log(classes);
  const navigate = useNavigate();
        const userMeta=JSON.parse(localStorage.getItem('master-app'));
  const createOrder = async () => {
    if(userId){
      try {
        const response = await axios.post(`https://www.creativekidsworld.org/masterclass/create-order/${bookId}`);
        setOrderId(response.data.id);
        openRazorpay(response.data.id);
      } catch (error) {
        console.error('Error creating order:', error);
      }
    }
    else{
      navigate('/login');
    }

  };

  const purchaseBook = async () => {
    try {
      console.log("Userid:  ",userId);
      if(userId!==undefined){
        const response = await axios.post(`https://www.creativekidsworld.org/masterclass/purchase-book/${bookId}/${userId}`);
        console.log(response.data,'rehan');
        if(response){
          window.location.reload();
        }
      }else{
        console.log("User id is undefined");
      }
 
      // Handle success, e.g., show a success message to the user
    } catch (error) {
      console.error('Error purchasing book:', error);
    }
  };

  const openRazorpay = (orderId) => {
    // Use Razorpay script to open the payment page
    const options = {
      key: 'rzp_live_d1jWD7BeYD6o1u', // Replace with your Razorpay key_id
      amount: 1000, // Example amount (in paise)
      currency: 'INR',
      name: 'Creative Kids World',
      description: 'Book Purchase',
      order_id: orderId,
      handler: function (response) {
        // Handle successful payment
        // console.log('Payment success:', response);
        
        // Call your API endpoint to complete the purchase
        purchaseBook();
       
      },
      prefill: {
        name: 'John Doe', // Replace with user's name
        email: 'john@example.com', // Replace with user's email
        contact: '+1234567890', // Replace with user's phone number
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  return (
    <div>
      <button disabled={classes.userId.includes(userMeta?.data)} onClick={createOrder}>{classes.userId.includes(userMeta?.data)?'Booked':'Book'}</button>
    </div>
  );
};

export default PaymentComponent;
