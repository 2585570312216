import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { Link, animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import PaymentComponent from '../razorpay/payment';
import { useLocation } from 'react-router-dom';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import './App.css';
import { useRef } from 'react';

import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import icon from './16.png'; 
import tickIcon from './circle.png';
import EnglishClassesfetched from './EnglishClassesfetched';





const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const ImageSlider = () => {
	const images = [
	  '19.png',
	  '20.png',
	  '21.png',
	  '22.png'
	];
	
	const [currentImageIndex, setCurrentImageIndex] = useState(0);

	useEffect(() => {
	  const interval = setInterval(() => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	  }, 3000); // Change image every 3 seconds (adjust as needed)
  
	  return () => clearInterval(interval);
	}, [images.length]);
  
	const handleClickPrev = () => {
	  setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
	};
  
	const handleClickNext = () => {
	  setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};
  
	return (
	  <div style={{ width: '95%',margin: '0 auto', position: 'relative' }}>
		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
		  <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px' }} onClick={handleClickPrev}>{'<'}</button>
		  <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
			{[currentImageIndex - 1, currentImageIndex, currentImageIndex + 1].map((index) => (
			  <div key={index} style={{ width: '33%',  padding: '8px', textAlign: 'center', marginRight: '1%',marginLeft:'1%' }}>
				<img
				  src={images[(index + images.length) % images.length]}
				  alt={`${index}`}
				  style={{
					maxWidth: '100%',
					maxHeight: '100%',
					borderRadius: '5px',
					transform: index === currentImageIndex ? 'scale(1.2)' : 'scale(1)',
					border: index === currentImageIndex ? '3px solid #5E17EB' : 'none'
				  }}
				/>
			  </div>
			))}
		  </div>
		  <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px' }} onClick={handleClickNext}>{'>'}</button>
		</div>
	  </div>
	);
  };
	  
  const TextSlider = () => {
    const texts = ['Art of Storytelling', 'Logical Thinking', 'Self Esteem & Confidence building', 'Starting Conversations', 'Extempore Speech', 'Master Shyness'];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [fontSize, setFontSize] = useState(18); // Initial font size
    const textRef = useRef(null);

    useEffect(() => {
        // Calculate font size based on available space
        const calculateFontSize = () => {
            if (textRef.current) {
                const maxWidth = textRef.current.offsetWidth;
                const textWidth = textRef.current.scrollWidth;
                const newFontSize = Math.floor(fontSize * (maxWidth / textWidth));
                setFontSize(newFontSize);
            }
        };

        calculateFontSize(); // Initial font size calculation

        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 3000); // Change text every 3 seconds (adjust as needed)

        // Recalculate font size on window resize
        window.addEventListener('resize', calculateFontSize);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', calculateFontSize);
        };
    }, [fontSize, texts.length]);

    const handleClickPrev = () => {
        setCurrentTextIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
    };

    const handleClickNext = () => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    return (
        <div style={{ width: '85%', margin: '0 auto', position: 'relative' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '10px', borderRadius: '5px' }} onClick={handleClickPrev}>{'<'}</button>
                <div ref={textRef} style={{ width: '95%', display: 'flex', justifyContent: 'space-around' }}>
                    {[currentTextIndex - 1, currentTextIndex, currentTextIndex + 1].map((index) => (
                        <div key={index} style={{
                            width: '32%', border: '1px solid black', backgroundColor: index === currentTextIndex ? '#5E17EB' : 'transparent', padding: '10px', textAlign: 'center'
                        }}>
                            <h2 style={{ color: index === currentTextIndex ? 'white' : 'black', fontWeight: 'bold', fontSize: `${fontSize}px`, margin: '0' }}>{texts[(index + texts.length) % texts.length]}</h2>
                        </div>
                    ))}
                </div>
                <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '10px', borderRadius: '5px' }} onClick={handleClickNext}>{'>'}</button>
            </div>
        </div>
    );
};
function Englishclasses() {

const [country, setCountry] = useState('');
const [showStateField, setShowStateField] = useState(false);
const handleCountryChange = (event) => {
  setCountry(event.target.value);
  if (event.target.value === 'India') {
    setShowStateField(true);
  } else {
    setShowStateField(false);
  }
};


const countriesOfWorld = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czechia",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
];
  const statesOfIndia = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
	"Jammu and Kashmir",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
    
  ];

  // Array of grades from 1 to 12
  const grades = Array.from({ length: 12 }, (_, i) => i + 1);

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData(event.target); // Create FormData object from form
    const requestData = Object.fromEntries(formData.entries()); // Convert FormData to object

    try {
      const response = await fetch('https://www.creativekidsworld.org/callBackDetails/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData) // Convert object to JSON string
      });

      if (response.ok) {
        // Handle success response
        console.log('Form data submitted successfully!');
        window.location.reload(); 
      } else {
        // Handle error response
        console.error('Failed to submit form data');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div style={{ position: 'relative', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
      <button
        style={{
          position: 'fixed',
          top: '22%',
          left: '2%',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          backgroundColor: '#5E17EB',
          color: '#fff',
          border: 'none',
          outline: 'none',
          cursor: 'pointer'
        }}
        onClick={scrollToTop}
      >
        &uarr;
      </button>
	  <Header pagename='englishpage'/>
      <h1 style={{ textAlign: 'center',fontWeight:'bold' ,marginTop:'5%'}}>Spoken English & Personality Development Classes</h1>
      <div style={{ position: 'absolute', left: '5%', width: '50%', height: '80vh', borderRadius: '20px', overflow: 'auto', marginTop:'4%',}}>
        <div style={{  backgroundColor: 'rgba(0, 0, 0, 0.75)', padding: '20px', borderRadius: '20px' }}>
          <h2 style={{ textAlign: 'center', color: '#fff', marginBottom: '20px', fontSize: '26px' }}>Register to get a callback</h2>
          <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column',gap: '2px' ,color: '#fff' }}>
            <label style={{ width: '100%',color:'white' }}>
              Parent's Name:
              <input type="text" name="parentsName" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px' }} required />
            </label>
            <br />
            <label style={{ width: '100%' ,color:'white'}}>
              Kid's Name:
              <input type="text" name="kidName" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required />
            </label>
            <br />
            <label style={{ width: '100%' ,color:'white'}}>
              Kid's Grade:
              <select name="kidGrade" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required>
                <option value="" disabled selected>Select Grade</option>
                {grades.map((grade) => (
                  <option key={grade} value={grade}>{grade}</option>
                ))}
              </select>
            </label>
            <br />
            <label style={{ width: '100%',color:'white' }}>
              Country:
              <select  onChange={handleCountryChange} name="Country" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none' ,height:'25px' }} required>
                <option value="" disabled selected>Select Country</option>
                {countriesOfWorld.map((country, index) => (
                  <option key={index} value={country}>{country}</option>
                ))}
              </select>
            </label>
            <br />
            {showStateField && (
        <label style={{ width: '100%', color: 'white' }}>
          State:
          <select
            name="state"
            style={{
              borderRadius: '5px',
              width: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.75)',
              border: 'none',
              height: '25px'
            }}
            required
          >
            <option value="" disabled selected>
              Select State
            </option>
            {statesOfIndia.map((state, index) => (
              <option key={index} value={state}>
                {state}
              </option>
            ))}
          </select>
        </label>
      )}
      <br />
            <label style={{ width: '100%',color:'white' }}>
              Parent's Phone No.:
              <input type="tel" name="parentsPhone" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required pattern="[0-9]{10}" title="Please enter a 10-digit phone number" />
            </label>
            <br />
            <label style={{ width: '100%' ,color:'white'}}>
              Parent's E-mail:
              <input type="email" name="parentsEmail" style={{ borderRadius: '5px', width: '100%', backgroundColor: 'rgba(255, 255, 255, 0.75)', border: 'none',height:'25px'  }} required />
            </label>
            <br />
            <button type="submit" style={{ borderRadius: '20px', backgroundColor: '#5E17EB', color: '#fff', width: 'auto', alignSelf: 'center', marginTop: '2%', padding: '10px', border: 'none', cursor: 'pointer',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}} onMouseOver={(e) => e.target.style.backgroundColor = '#4189E1'} onMouseOut={(e) => e.target.style.backgroundColor = 'royalblue'}>Request a callback</button>
          </form>
        </div>
      </div>
      <img src="./17.png" alt="placeholder" style={{ objectFit: 'cover', width: '100%', height: '85vh', borderRadius: '10px' }} />
      <div style={{ marginTop:'80px', marginBottom:'80px', fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
  <h2 style={{ textAlign: 'center', fontSize:'52px' }}>Advantages</h2>
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', gap: '20px',width:'80%',justifyContent: 'center', marginLeft:'10%',marginRight:'10%', overflow:'auto' }}>
    <div style={{ textAlign: 'center' }}>
      <img src={icon} alt="Icon" style={{ width: '100px', height: '100px' }} />
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Affordable Prices</h3>
      <h4>Guarantee of lowest prices for Best 1-to-1 online English speaking course in India</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <img src={icon} alt="Icon" style={{ width: '100px', height: '100px' }} />
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>One to One Classes</h3>
      <h4>Tailored course content and 100% attention towards each student</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <img src={icon} alt="Icon" style={{ width: '100px', height: '100px' }} />
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Easy Refund</h3>
      <h4>Refund within 7 days of purchase if you have not taken any class in our English speaking course</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <img src={icon} alt="Icon" style={{ width: '100px', height: '100px' }} />
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Experienced Teachers</h3>
      <h4>Expert Online English tutors who understand your challenges</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <img src={icon} alt="Icon" style={{ width: '100px', height: '100px' }} />
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Flexible Timing</h3>
      <h4>Choose any time you want. Students can choose different times for each class</h4>
    </div>
    <div style={{ textAlign: 'center' }}>
      <img src={icon} alt="Icon" style={{ width: '100px', height: '100px' }} />
      <h3 style={{ marginTop: '10px',fontWeight:'bolder' }}>Focus on Speaking</h3>
      <h4>Maximum English speaking practice. Students speak for minimum 50-70% of class duration</h4>
    </div>
  </div>
</div>

    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      <h2 style={{ margin: '20px 0', fontSize:'52px' ,fontWeight:'bold'}}>What you will learn in the online Spoken English & Personality Development Classes!</h2>
      <h3 style={{ width: '75%', margin: '0 auto', marginBottom: '30px',fontWeight:'normal',fontSize:'20px' }}>THIS ONLINE COURSE WILL HELP YOU TAKE YOUR ENGLISH LANGUAGE ABILITY, COMMUNICATION SKILLS AND OVERALL PERSONALITY TO THE NEXT LEVEL. YOU WILL LEARN :</h3>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '20px', marginBottom: '5%', justifyContent: 'center', marginLeft:'20%',marginRight:'20%' }}>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Sentence Structure</h3>
            <h4 style={{ fontWeight: 'normal'}}>How to structure your sentences effectively and correctly</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Vocabulary</h3>
            <h4 style={{ fontWeight: 'normal' }}>How to expand your vocabulary to create sophisticated sentences</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left'}}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Linguistic Concepts</h3>
            <h4 style={{ fontWeight: 'normal' }}>Advanced grammar rules, idioms, phrasal verbs and other key linguisitic features</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Tips and Techniques</h3>
            <h4 style={{ fontWeight: 'normal' }}>New tips and techniques to express yourself more confidently and fluently</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Pronunciation</h3>
            <h4 style={{ fontWeight: 'normal' }}>The nuances of english pronunciation and intonation</h4>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center',gap: '10px' ,textAlign:'left' }}>
          <img src={tickIcon} alt="Tick Icon" style={{ width: '40px', height: '40px' }} />
          <div>
            <h3 style={{fontWeight:'bold'}}>Practice, Practice, Practice!</h3>
            <h4 style={{ fontWeight: 'normal' }}>To help you understand and speak english with ease</h4>
          </div>
        </div>
      </div>
    </div>

    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      {/* Image with rounded corners */}
      <img src="8.png" alt="Your Image" style={{ width: '75vw', borderRadius: '20px' }} />
    </div>
<div style={{ marginTop: '5%', display: 'flex', justifyContent: 'center' }}>
    <TextSlider />
</div>
  <div style={{ marginTop:'3%',fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
  <h2 style={{ fontSize: '52px', margin: '20px 0',fontWeight:'bold'}}>How and where this course will help your child</h2>
  
  {/* Grid container for pictures */}
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(350px, 1fr))', gap: '20px', marginBottom: '40px', marginLeft:'5%',marginRight:'5%'}}>
    <div>
        <img src="7.png" alt="Image 1" style={{ width: '100%', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontSize: '24px', fontWeight:'bold' }}>Day to Day conversations</h3>
    </div>
    <div>
        <img src="6.png" alt="Image 2" style={{ width: '100%', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontSize: '24px', fontWeight:'bold' }}>Public Speaking</h3>
    </div>
    <div>
        <img src="5.png" alt="Image 3" style={{ width: '100%', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontSize: '24px', fontWeight:'bold' }}>Assembly Presentation</h3>
    </div>
    <div>
        <img src="4.png" alt="Image 4" style={{ width: '100%', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontSize: '24px', fontWeight:'bold' }}>Project Presentations</h3>
    </div>
    <div>
        <img src="3.png" alt="Image 5" style={{ width: '100%', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontSize: '24px', fontWeight:'bold' }}>Debates</h3>
    </div>
    <div>
        <img src="2.png" alt="Image 6" style={{ width: '100%', borderRadius: '10px' }} />
        <h3 style={{ marginTop: '10px', fontSize: '24px', fontWeight:'bold' }}>Elocution & Declamation</h3>
    </div>
</div>

</div>
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      <h2 style={{ margin: '20px 0',fontSize:'52px' ,fontWeight:'bold'}}>Our Course plans</h2>
      <h3 style={{ marginBottom: '20px', fontSize:'26px',fontWeight:'bold',background:'#5E17EB',width:'60%',marginLeft:'20%',color:'white' }}>OFFER- We offer upto{" "}
					<span style={{color:'red'}}>
						10%
					</span>
					{" "}discount if two of your kids study with us.</h3>
      
					<EnglishClassesfetched />
    </div>
    <div>
      <h1 style={{textAlign:'center',fontSize:'52px',marginTop:'3%', marginBottom:'5%',fontWeight:'bold'}}>
        What you get
      </h1>
     
      <ImageSlider/>
    </div>
    <div style={{ marginTop:'5%',fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' }}>
      <h2 style={{ fontSize:'52px',margin: '20px 0' , fontWeight:'bold',marginBottom:'5%'}}>How we work</h2>
      
      {/* Container for the grid */}
      <div style={{ marginLeft: '5%', marginRight: '5%', display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))', gap: '25px', justifyContent: 'center' }}>
    {/* Box 1 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 1</h4>
        <h3 style={{ fontSize: '18px' }}>Fill the form and make payment</h3>
    </div>

    {/* Box 2 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 2</h4>
        <h3 style={{ fontSize: '18px' }}>Student ID will be generated</h3>
    </div>

    {/* Box 3 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 3</h4>
        <h3 style={{ fontSize: '18px' }}>Get batch ID before joining the class</h3>
    </div>

    {/* Box 4 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 4</h4>
        <h3 style={{ fontSize: '18px' }}>Login credentials will be sent to you</h3>
    </div>

    {/* Box 5 */}
    <div style={{ padding: '20px', border: '2px solid #5E17EB', borderRadius: '10px', minWidth: '200px', textAlign: 'center' }}>
        <h4 style={{ fontWeight: 'bold' }}>Step 5</h4>
        <h3 style={{ fontSize: '18px' }}>Congrats! You became a student of Creative Kids World class.</h3>
    </div>
</div>

    </div>
    <div style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif', textAlign: 'center' ,marginBottom:'5%'}}>
      <h2 style={{ fontSize: '52px', margin: '20px 0',fontWeight:'bold',marginBottom:'5%',marginTop:'5%' }}>Frequently asked questions</h2>
      
      {/* FAQ divisions */}
      <div style={{ maxWidth: '75vw', margin: '0 auto' }}>
        {/* FAQ 1 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Is it Possible to study from a smartphone?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes, Definitely! Your child can attend all the sessions from a Smartphone.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 2 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>How long are the sessions?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>The sessions are designed for 40 minutes each.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 3 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Can my child attend the classes from anywhere?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes, absolutely! your child can attend the classes from anywhere. Just make sure you have a good internet connectivity for the Live sessions.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 4 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Will you provide study material for the courses?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes, we will provide FREE study material in soft copy format that can be used during and after the classes for revisions, homework etc.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 5 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Can students use their own study material?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Yes. You may use your own study material. Our teachers will be more than happy to help you.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 6 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Do Parents need to be at the class?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>We recommend parents to be present in the first lesson for child's familiarity with the teacher. Our teachers are expert, trained and have vast experience in online teaching.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 7 */}
        <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '30px' ,marginTop:'30px' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>What is the age limit for kids?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>Kids between the age group of 4 years to 16 years are eligible to be enrolled in Spoken English and Personality development classes.</h3>
          </div>
        </div>
        <hr style={{ width: '100%', margin: '0 auto', marginBottom: '40px', borderWidth: '2px', borderColor: 'rgba(0, 0, 0, 1)' }} />
        
        {/* FAQ 8 */}
        <div style={{ display: 'flex', alignItems: 'flex-start' , marginBottom: '30px' ,marginTop:'30px'}}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>What are the payment options?</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '60%', textAlign: 'left', fontSize: '20px' }}>
            <h3 style={{ marginBottom: '10px', fontSize: '20px' }}>You can make payment through the secure channel of Razorpay via Net Banking, UPI, Debit Card, Credit Card, etc.</h3>
          </div>
        </div>
      </div>
    </div>
	<Footer/>
    </div>
    
  );
}

export default Englishclasses;
