import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";
import Header from "../header/Header";
import { useNavigate  } from 'react-router-dom';
import Footer from '../footer/Footer';

const Blog =()=>{
    const navigate = useNavigate();
    const goToHome=()=>{
        navigate(`/home`);
    }
return(
    <>
    <Container>
    <Navbar expand="lg" className="bg-body-tertiary header">
      
      <Navbar.Brand href="#home">
      <img style={{ width: '95px',paddingLeft: '7px' }} src='Logo_website.png'></img>
          {/* <span className='explore'>Exoplore-Imagine-Create</span> */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse className='colaps' id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="https://www.creativekidsworld.org/" style={{color: 'white',marginLeft:'35px'}}>Home</Nav.Link>
          <Nav.Link href="https://myadventurebook.org/" style={{color:"white",marginLeft:'35px'}}>Product</Nav.Link>
          <NavDropdown title="Services" id="basic-nav-dropdown" style={{color:'white',marginLeft:'35px'}}>
          <NavDropdown.Item onClick={goToHome}>
Master Classes
</NavDropdown.Item>
<NavDropdown.Item onClick={() => goToHome('story')}>
                Story Telling
              </NavDropdown.Item>
              <NavDropdown.Item
              onClick={() => {
                navigate('/es');
                window.location.reload(); // Reload the page
              }}
            >
              Spoken English Classes
            </NavDropdown.Item>
            <NavDropdown.Item
  onClick={() => {
    navigate('/ec');
    window.location.reload(); // Reload the page
  }}
  style={{ textDecoration: 'none' }} // Remove underline
>
  <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
</NavDropdown.Item>
<NavDropdown.Item onClick={() => goToHome('innovation')}>
                  <div style={{ color: 'red', textDecoration: 'none' }}>Innovation Classes </div>
              </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="https://drive.google.com/file/u/1/d/1hEWSYOT3Yurr7pI5-vwkSCwQxa9dlwg8/view" style={{color: 'white',marginLeft:'35px'}}>Media</Nav.Link>

  <div>
  <Nav.Link href="/blog" style={{color: 'white',marginLeft:'35px'}}>Blog</Nav.Link>
  </div>
  <Nav.Link href="https://mabkart.org/" target="_blank" style={{ color: "white", marginLeft: '2px' }}>
            Merchandise 
            </Nav.Link>
        
        </Nav>
      </Navbar.Collapse>
   
  </Navbar>
    </Container>
 
    <Row>
        <Col lg={12} style={{padding: '12px 50px',paddingBottom:'0px'}}>
            <img src="Imagination-children.png" height={"100%"} width={"100%"}></img>
        </Col>
    </Row>
    <Row>
        <Col lg={12}>
        <article>
    <h4>Nurturing Brilliance: The Crucial Role of Creativity and Curiosity in Child Development</h4>

    <p>
      Parenthood is an incredible journey marked by joy, challenges, and the constant quest to provide the best for our children. In the ever-evolving landscape of education and child development, two pillars stand out as indispensable: creativity and curiosity. These qualities are not just the building blocks of a well-rounded individual; they are the keys to unlocking a child's full potential.
    </p>

    <h4> The Dynamic Duo: Creativity and Curiosity</h4>
    <p>
      Creativity and curiosity go hand in hand, creating a synergy that propels a child's development forward. Curiosity sparks the flame, and creativity fans it into a vibrant blaze. Together, they form a powerful duo that lays the foundation for lifelong learning and success.
    </p>

    <h4> Unleashing the Imagination: The Power of Creativity</h4>
    <p>
      Creativity is not limited to artistic endeavors; it's a mindset that encourages thinking outside the box. When children are given the freedom to express themselves creatively, whether through art, storytelling, or problem-solving, they develop a range of skills essential for navigating an increasingly complex world.
    </p>

    <h4> Curiosity: The Gateway to Knowledge</h4>
    <p>
      Curiosity is the spark that ignites the flame of learning. It's the insatiable desire to understand the world, ask questions, and seek answers. Cultivating curiosity in children not only makes learning enjoyable but also sets the stage for a lifelong love of knowledge.
    </p>

    <h4> Practical Tips for Fostering Creativity and Curiosity</h4>
    <ul>
      <li><strong>Encourage Play:</strong> Allow unstructured play to let children use their imagination freely.</li>
      <li><strong>Celebrate Mistakes:</strong> Embrace mistakes as valuable learning experiences, promoting a growth mindset.</li>
      <li><strong>Expose Them to Diverse Experiences:</strong> Broaden their horizons by introducing various activities, cultures, and perspectives.</li>
    </ul>

    <h4> Conclusion: Building a Foundation for a Bright Future</h4>
    <p>
      In a world that values innovation and adaptability, fostering creativity and curiosity in our children is paramount. As parents, we play a crucial role in nurturing these qualities, laying the groundwork for success in both their personal and professional lives. By embracing the dynamic duo of creativity and curiosity, we empower our children to not only thrive in the present but also to shape a future filled with endless possibilities.
    </p>
  </article>
        </Col>
    </Row>
    <Row>
        <Col lg={12} style={{padding: '12px 50px',paddingBottom:'0px'}}>
            <img src="explore-imagine-create-children.png" height={"100%"} width={"100%"}></img>
        </Col>
    </Row>

    <Row>
        <Col lg={12}>
        <article>
    <h1>The Magic Within: Unleashing the Power of Imagination in Your Child's Development</h1>

    <p>
      As parents, we witness the magic of childhood every day—the sparkle in their eyes when they tell fantastical tales or transform an ordinary cardboard box into a spaceship. Behind these moments of enchantment lies a crucial aspect of your child's development: their imagination. Imagination is not just a whimsical escape; it's a powerful tool that shapes cognitive, emotional, and social growth. Let's explore how nurturing your child's imagination lays the groundwork for a lifetime of learning and creativity.
    </p>

    <h2> Cognitive Building Blocks:</h2>
    <p>
      Imagination serves as a mental playground where children can explore limitless possibilities. Through imaginative play, whether it's pretending to be superheroes, engaging in make-believe scenarios, or creating imaginary worlds, children develop essential cognitive skills.
    </p>
    <ul>
      <li><strong>Problem-Solving:</strong> Imaginative play often involves overcoming challenges and obstacles, fostering problem-solving skills as children navigate through their imaginary adventures.</li>
      <li><strong>Critical Thinking:</strong> Imagination encourages thinking beyond the obvious, promoting critical thinking as children analyze situations and make decisions in their imaginative worlds.</li>
    </ul>

    <h2> Emotional Intelligence:</h2>
    <p>
      The ability to understand and manage emotions is a vital aspect of emotional intelligence. Imagination provides a safe space for children to express and process their feelings, promoting emotional well-being.
    </p>
    <ul>
      <li><strong>Empathy:</strong> Imaginative play allows children to step into different roles, fostering empathy as they consider the perspectives and feelings of the characters they create.</li>
      <li><strong>Self-Expression:</strong> Through imaginative storytelling or creative activities, children can express complex emotions, helping them develop a healthy emotional outlet.</li>
    </ul>

    <h2> Social Skills Development:</h2>
    <p>
      Imagination is a social glue that brings children together in shared worlds of play. Collaborative and cooperative play not only enhances social skills but also lays the foundation for positive relationships.
    </p>
    <ul>
      <li><strong>Communication:</strong> Imaginary play often involves verbal communication and negotiation, improving language skills and the ability to express ideas clearly.</li>
      <li><strong>Teamwork:</strong> Collaborative imaginative play teaches children the importance of teamwork, sharing, and compromise—a valuable skill set for future social interactions.</li>
    </ul>

    <h2> Cultivating a Love for Learning:</h2>
    <p>
      Imagination is the spark that ignites a love for learning. Children who are encouraged to explore their creative thoughts and ideas develop a natural curiosity that extends into their academic pursuits.
    </p>
    <ul>
      <li><strong>Intrinsic Motivation:</strong> Imagination fosters a sense of curiosity and intrinsic motivation, driving children to seek knowledge and explore the world around them.</li>
      <li><strong>Lifelong Learners:</strong> By embracing imaginative thinking, children develop a mindset that values continuous learning, adaptability, and innovation.</li>
    </ul>

    <h2> Practical Tips for Fostering Imagination:</h2>
    <ul>
      <li><strong>Provide Open-Ended Toys:</strong> Toys that allow for open-ended play, such as building blocks, art supplies, and dress-up clothes, encourage imaginative exploration.</li>
      <li><strong>Create an Imaginative Environment:</strong> Designate a space at home where your child can engage in imaginative play comfortably, whether it's a cozy reading nook or a creative corner for art projects.</li>
      <li><strong>Encourage Storytelling:</strong> Foster a love for narrative by encouraging your child to create and share their own stories, either verbally or through drawing.</li>
    </ul>

    <h2>Conclusion:</h2>
    <p>
      Imagination is the bridge between reality and limitless possibilities. By embracing and nurturing your child's imagination, you not only enhance their cognitive abilities, emotional intelligence, and social skills but also lay the foundation for a future filled with creativity, curiosity, and a lifelong love for learning. Embrace the magic within your child, for it is through their imagination that they'll discover the extraordinary in the ordinary and embark on a journey of endless possibilities.
    </p>
  </article>
        </Col>
    </Row>
    <Footer></Footer>
    </>
)

}


export default Blog;