
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import React from "react";
import { useState, useEffect } from 'react';

import prevArrowImage from './left.png';
import nextArrowImage from './right.png';



	
const ImageSlider = ({ images }) => {

	const settings = {
		infinite: true,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		autoplaySpeed: 2000,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />
	};
	return (
		<>
			<div id="background-div" className="tag">
				<h1 id='Testimonials'>What Parents Have to Say</h1>
			
			<div  className="imgslider">
				<Slider {...settings}>
					{images.map((item) => (
						<div key={item.id}>
							<img
								src={item.src}
								alt={item.alt}
							/>
						</div>
					))}
				</Slider>
			</div>
			</div>
		</>
	);
};

const CustomPrevArrow = (props) => {
	const [dimensions, setDimensions] = useState({ width: '150px', height: '150px' }); // Initial dimensions
  
	useEffect(() => {
	  const userAgent = navigator.userAgent.toLowerCase();
  
	  // Check if the user agent string contains 'mobile'
	  if (userAgent.includes('mobile')) {
		setDimensions({ width: '50px', height: '50px' }); // Set dimensions for mobile devices
	  } else {
		setDimensions({ width: '150px', height: '150px' }); // Set dimensions for other devices
	  }
	}, []); // Runs once after component mounting
	const { className, style, onClick } = props;
	return (
	  <img
		src={prevArrowImage}
		className={className}
		style={{ ...style, display: 'block', width: dimensions.width, height: dimensions.height }} // Customize width and height as per your image size
		onClick={onClick}
		alt="Previous"
	  />
	);
  };
  
  const CustomNextArrow = (props) => {
	const [dimensions, setDimensions] = useState({ width: '150px', height: '150px' }); // Initial dimensions
  
	useEffect(() => {
	  const userAgent = navigator.userAgent.toLowerCase();
  
	  // Check if the user agent string contains 'mobile'
	  if (userAgent.includes('mobile')) {
		setDimensions({ width: '50px', height: '50px' }); // Set dimensions for mobile devices
	  } else {
		setDimensions({ width: '150px', height: '150px' }); // Set dimensions for other devices
	  }
	}, []); // Runs once after component mounting
	const { className, style, onClick } = props;
	return (
	  <img
		src={nextArrowImage}
		className={className}
		style={{ ...style, display: 'block', width: dimensions.width, height: dimensions.height }} // Customize width and height as per your image size
		onClick={onClick}
		alt="Next"
	  />
	);
  };
export default ImageSlider;
