import { Col, Container, Row } from "react-bootstrap";
import './otp-auth.css';
import OtpInputWithValidation from "./OtpInputWithValidation";
import { useLocation } from 'react-router-dom';
import Timer from "./Timer";

function OtpAuth() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    console.log('Param: ', queryParams.get('email'))
    const email = queryParams.get('email');
    const phone = queryParams.get('phone');
    const authKey = email ? email : phone;

    return (
        <div>
            <Container>
                <Row>
                    <Col lg={3}></Col>
                    <Col lg={6} className="otp-container">
                        <div style={{ padding: '20px', lineHeight: '5' }}>
                            <h3 style={{ color: 'blue' }}><u>Login</u></h3>
                            <p style={{
                                fontSize: '28px !importent', textAlign: "center", color: '#4a3a3ae8',
                                fontWeight: '500'
                            }}>We have sent you <b>one time password</b> to </p>
                            {/* <p style={{fontSize: '14px',
    fontWeight: '600'}}>Otp has been sent.</p> */}
                            <h6 style={{
                                fontSize: '18px', textAlign: 'center', color: '#ff0039bd',
                                fontWeight: '600'
                            }}>{authKey}</h6>
                            <Timer />
                        </div>
                        <div style={{
                            width: '100%',

                           
                        }}>
                            <OtpInputWithValidation numberOfDigits={6} />

                        </div>

                    </Col>
                    <Col lg={3}></Col>

                </Row>
            </Container>
        </div>
    )
}

export default OtpAuth