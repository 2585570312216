import { Col, Container, Row } from "react-bootstrap";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './todayclasses.css';
const TodayStory=()=>{
    const [allTodayClass,setAllTodayClass]=useState([]);

    useEffect(() => {
        getAllTodayClass();
        },[]);

        const userid=JSON.parse(localStorage.getItem('master-app'));

        const getAllTodayClass = async () => {
            try {
        
              const resp = await axios.get('https://www.creativekidsworld.org/storyTelling/todayclass',{
                params: {userId:userid.data},
              });
              console.log(resp.data.data);
              const responseData = Array.isArray(resp.data) ? resp.data : [resp.data];

              setAllTodayClass(resp.data.data);
        
            } catch (error) {
              console.log("Error: ",error);
            }
          }
    return(
        <>
       <Container>
      <h1 style={{ textAlign: 'center' }}>Today Classes</h1>
      <Row style={{ margin: "20px 10px" }}>
        {allTodayClass.map((item) => (
          <Col lg={4} key={item.data.id}>
            {/* Ensure to add a unique key for each element in the map */}
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={item.data.image} />
              <Card.Body>
                <Card.Title>{item.data.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.data.timeSlot}</Card.Subtitle>\
                <Card.Text className="desc">{item.data.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>

     
        </>
    )
}

export default TodayStory;