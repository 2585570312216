
import React, { useRef, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useNavigate  } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert2';

const correctOTP = "1234" // fetched from your server
function OtpInputWithValidation({ numberOfDigits }) {
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // console.log('Param: ',queryParams.get('email'))
  const email=queryParams.get('email');
  const phone=queryParams.get('phone');
  // console.log(phone);

  const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);
  const [userId, setUserId] = useState(null);
  
  const navigate = useNavigate();
  const otps=Number(otp.join(''), 10)
  console.log(otps);
  const loginStatus=JSON.parse(localStorage.getItem('loginstatus'));
  

  const doRegister=async ()=>{
    try{
      let source='';
      if(email){
        source={email:email,otp:otps};
      }else{
        source={MobileNumber:`+${phone}`.replace(/\s/g, ''),otp:otps};
      }
        const resp= await axios.post('https://www.creativekidsworld.org/user/verify',source);
      

    // setTimeout(() => {
    //   console.log(userId, 'rehan');
    // }, 1000);
          if(resp.data.status==true){
            console.log('Setting userId:', resp.data.data);
            localStorage.setItem('master-app',JSON.stringify(resp['data']));
            setUserId(resp.data.data);
            swal.fire({
              title: 'Success',
              text: 'Account verified successfully.',
              icon: 'success',
            });
          
              console.log(resp.data.userMeta);
              if(loginStatus=='/story'){
                navigate(`/story`);
              }else{
                navigate(`/?id=${resp['data']['data']}`);
              }
              
          }
          if(resp.data.status==false){
            // console.log('Setting userId:', resp.data.data);
            localStorage.setItem('master-app',JSON.stringify(resp['data']));
            // setUserId(resp.data.data);
            swal.fire({
              title: 'Error',
              text: 'Incorrect Otp',
              icon: 'error',
            });
            
          }

          else{
            // navigate('/');
            console.log(resp['message']);
          }
          // console.log(resp.data.data);
          // setUserId(resp.data.data.userId);
    }catch(exeption){
      console.log(exeption);
    }
  }
  useEffect(() => {
    // Check if userId is not null before logging
   
      console.log(userId, 'rehan444');
    
  }, [userId]);
  function handleChange(value, index) {
    let newArr = [...otp];
    newArr[index] = value;
    setOtp(newArr);
      console.log(newArr);
    if(value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if(e.key === "Backspace" && !e.target.value && index > 0){
      otpBoxReference.current[index - 1].focus()
    }
    if(e.key === "Enter" && e.target.value && index < numberOfDigits-1){
      otpBoxReference.current[index + 1].focus()
    }
  }

  useEffect(() => { 
    if(otp.join("") !== "" && otp.join("") !== correctOTP){
      setOtpError("Wrong OTP Please Check Again")
    }else{
      setOtpError(null)
    } 
   }, [otp]);
  
  return (
    <>
        <article className="w-1/2">
      {/* <p className="text-2xl font-medium text-white">OTP Input With Validation</p>
      <p className="text-base text-white mt-4 bg-[#323232] p-4 rounded-md">A special type of input box where as user types, it checks if the otp is correct else it shows an error message below with a shake animation.</p>
      
      <p className="text-base text-white mt-6 mb-4">One Time Password (OTP)</p>
      */}
      {/* <p className={`text-lg  mt-4 ${otpError ? 'error-show' : ''}`} style={{color:"red"}}>{otpError}</p> */}

     <div className='flex items-center gap-4' style={{display:'flex',justifyContent:'space-between',}}>
      {otp.map((digit, index)=>(
        <input key={index} value={digit} maxLength={1}   className='form-control'
        onChange={(e)=> handleChange(e.target.value, index)}
        onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
        ref={(reference) => (otpBoxReference.current[index] = reference)}
        style={{    width: '10%',    border: '1px solid blue'}}       />
      ))}

     </div>
     <Button style={{marginTop: '49px',

    backgroundColor:'#11115c',
    marginLeft: '30%',
    width: '40%'}} onClick={doRegister} variant="secondary">OK</Button>
    </article>
    </>

  );
}

export default OtpInputWithValidation;