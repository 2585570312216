import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-scroll";
import { NavDropdown } from "react-bootstrap";
import { MdAlignHorizontalCenter } from "react-icons/md";

function Header(pagename) {
  const navigate = useNavigate();
  const goToHome = (page) => {
    navigate(`/home?pagename=${page}`);
  };

  const goToDashboard = () => {
    navigate(`/book-status?pagename=${pagename.pagename}`);
  };
  const doLogin = () => {
    let currentRoute = window.location.pathname;
    console.log(currentRoute);
    localStorage.setItem("loginstatus", JSON.stringify(currentRoute));
    navigate("/login");
  };
  const handleClick = () => {
    localStorage.removeItem("master-app");
    navigate("/");
  };
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary header" style={{ zIndex: 9999 }}>
      <Navbar.Brand href="#">
        <img
          style={{ width: "111px", paddingLeft: "7px" }}
          src="Logo_website.png"
          alt="Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-center">
        <Nav className="me-auto">
          {/* No items in this section */}
        </Nav>

        <Nav className="text-center"> {/* Center-aligned items */}
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="https://myadventurebook.org/" target="_blank">
            Product
          </Nav.Link>
          <NavDropdown title="Services" id="basic-nav-dropdown">
            <NavDropdown.Item
              onClick={() => {
                goToHome("master");
                window.location.reload(); // Reload the page
              }}
            >
              Master Classes
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                goToHome("story");
                window.location.reload(); // Reload the page
              }}
            >
              Story Telling
            </NavDropdown.Item>
            <NavDropdown.Item
              onClick={() => {
                navigate('/es');
                window.location.reload(); // Reload the page
              }}
            >
              Spoken English Classes
            </NavDropdown.Item>
            <NavDropdown.Item
  onClick={() => {
    navigate('/ec');
    window.location.reload(); // Reload the page
  }}
  style={{ textDecoration: 'none' }} // Remove underline
>
  <div style={{ color: 'red', textAlign: 'center', textDecoration: 'none' }}>Entrepreneurship Class</div>
</NavDropdown.Item>
<NavDropdown.Item 
  onClick={() => {
    goToHome('innovation');
    window.location.reload();
  }}>
   <div style={{ color: 'red', textDecoration: 'none' }}>Innovation Classes </div>
</NavDropdown.Item>

          </NavDropdown>
          <Nav.Link
            href="https://drive.google.com/file/u/1/d/1hEWSYOT3Yurr7pI5-vwkSCwQxa9dlwg8/view"
            target="_blank"
          >
            Media
          </Nav.Link>
          <Nav.Link href="/blog">Blog</Nav.Link>
          <Nav.Link href="https://mabkart.org/" target="_blank" style={{ color: "white", marginLeft: '3px' }}>
          Merchandise 
            </Nav.Link>

          {localStorage.getItem("master-app") &&
          pagename.pagename !== "dashboard" ? (
            <Nav.Link onClick={goToDashboard}>Dashboard</Nav.Link>
          ) : null}

          {!localStorage.getItem("master-app") ? (
            <Nav.Link eventKey={2} onClick={doLogin}>
              <Button variant="warning">Login</Button>
            </Nav.Link>
          ) : null}
          {localStorage.getItem("master-app") ? (
            <Nav.Link eventKey={2} onClick={handleClick}>
              <Button variant="warning">Logout</Button>
            </Nav.Link>
          ) : null}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
