
import './App.css';
import Header from './components/header/Header';
import Calendar from './components/calender/Calendar';
import Footer from './components/footer/Footer.jsx';
import Login from './components/logn/Login.jsx';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import Home from './components/home/Home.jsx';
import OtpAuth from './components/otpAuth/OtpAuth.jsx';
import BookingClasses from './components/BookingClasses/BookingClasses.jsx';
import FirstPage from './components/first-page/FirstPage.jsx';
import Blog from './components/blog/Blog.jsx';
import Story from './components/Story-Telling/story.jsx';
import Englishclasses from './components/englishclass/english';
import EntrepreneurshipClass from './components/entrepreneurshipClass/entrepreneurship.jsx'
import StoryDashboard from './components/Story-Telling/dashboard/Dashboard.jsx';
import React from "react";
import logo from "./logo.svg";
//analytics
import ReactGA from 'react-ga';
  const TRACKING_ID = "G-813R2VPVBK"; // measurement_ID
  ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <Router>
    <>
    
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<FirstPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path='/otp-auth' element={<OtpAuth />} />
        <Route path='/book-status' element={<BookingClasses />} />
        <Route path='/stroy-dashboard' element={<StoryDashboard />} />
        <Route path='/blog' element={<Blog></Blog>} />
        <Route path='/story' element={<Story></Story>}/>
        <Route path='/es' element={<Englishclasses></Englishclasses>}/>
        <Route path='/ec' element={<EntrepreneurshipClass></EntrepreneurshipClass>}/>
      </Routes>
      {/* <Footer /> */}
     
    </>
  </Router>
  );
}

export default App;
