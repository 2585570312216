import Footer from "../../footer/Footer";
import Header from "../../header/Header";
import PastStory from "../past-classes/PastStory";
import TodayStory from "../today-calsses/TodayStory";
import UpcomingStory from "../upcomming/UpcomingStory";


const StoryDashboard=()=>{
    
    return(
        <>
        <Header></Header>
        <TodayStory />
        <PastStory />
        <UpcomingStory />
        <Footer></Footer>
        </>
    )
}

export default StoryDashboard;