import React, { useState } from 'react';
import axios from 'axios';
import swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

const PaymentComponent = ({ bookId, userId, classes }) => {
  const [orderId, setOrderId] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false); // State to control the visibility of success modal
  const navigate = useNavigate();
  const userMeta = JSON.parse(localStorage.getItem('master-app'));

  const createOrder = async () => {
    if (userId) {
      try {
        const response = await axios.post(`https://www.creativekidsworld.org/entrepreneurship/order/${bookId}`);
        setOrderId(response.data.id);
        openRazorpay(response.data.id);
      } catch (error) {
        console.error('Error creating order:', error);
      }
    } else {
      navigate('/login');
    }
  };

  const purchaseBook = async () => {
    try {
      if (userId !== undefined) {
        const response = await axios.post(`https://www.creativekidsworld.org/entrepreneurship/purchase/${bookId}/${userId}`);
        console.log(response.data, 'rehan');
        if (response) {
          setShowSuccessModal(true); // Show success modal on successful purchase
          // You can optionally reload the page here or perform any other action
        }
      } else {
        console.log("User id is undefined");
      }
    } catch (error) {
      console.error('Error purchasing book:', error);
    }
  };

  const openRazorpay = (orderId) => {
    const options = {
      key: 'rzp_live_d1jWD7BeYD6o1u',
      amount: 1000,
      currency: 'INR',
      name: 'Creative Kids World',
      description: 'Book Purchase',
      order_id: orderId,
      handler: function (response) {
        purchaseBook();
      },
      prefill: {
        name: 'John Doe',
        email: 'john@example.com',
        contact: '+1234567890',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    window.location.reload(); // Reload the page after closing the modal
  };

  return (
    <div>
      <Button variant="success" style={{ margin: '10px' }} disabled={classes.userId.includes(userMeta?.data)} onClick={createOrder}>
        {classes.userId.includes(userMeta?.data) ? 'Booked' : 'Book'}
      </Button>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Payment Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <p style={{ textAlign: 'center', fontSize: '22px' }}>Your booking is confirmed. We will send you the details shortly. <br/> Thank you 😊 !</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentComponent;
