
import Calendar from "../calender/Calendar";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useLocation } from 'react-router-dom';
function Home(){
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    console.log('Param: ', queryParams.get('pagename'))
    const pagename = queryParams.get('pagename');
    console.log(pagename);
    return(
        <>
           <Header pagename={pagename} />
          <Calendar pagename={pagename}  />
          <Footer pagename={pagename} />
        </>
    )
}

export default Home;