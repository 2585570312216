import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap';
import PaymentComponent from './pay.jsx';

const userMeta = JSON.parse(localStorage.getItem('master-app'));

const EntrepreneurshipClassesFetched = () => {
  const [entrepreneurshipClasses, setEntrepreneurshipClass] = useState([]);
  const [selectedClassFeature, setSelectedClassFeature] = useState([]);
  const [selectedClassDescription, setSelectedClassDescription] = useState([]);
  const [selectedClassTitle, setSelectedClassTitle] = useState([]);
  const [selectedClassItems, setSelectedClassItems] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.creativekidsworld.org/entrepreneurship/getData');
        setEntrepreneurshipClass(response.data['data']);
      } catch (error) {
        console.error('Error fetching entrepreneurship classes:', error);
      }
    };

    fetchData();
  }, []);

  const handleSeeMore = (feature, description, title, item) => {
    setSelectedClassFeature(feature);
    setSelectedClassDescription(description);
    setSelectedClassTitle(title);
    setSelectedClassItems(item);
    setShowModal(true);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1>Entrepreneurship Class </h1>
      <div className="class-container">
        {entrepreneurshipClasses?.map((classData, index) => (
          <div
            key={index}
            style={{
              border: '2px solid #5E17EB',
              borderRadius: '5px',
              padding: '10px',
              margin: '10px',
              display: 'inline-block',
            }}
          >
            {classData.classDetail?.map((item, i) => (
              <div key={i}>
                <h4 style={{ marginBottom: '5px' }}>Program Duration: <br/> {item.duration} <br/> 1 session per week </h4>
                <h2 style={{ fontWeight: 'bold', marginBottom: '10px' }}>{item.title}</h2>
                <h4>Price: INR {item.price} </h4>

                {/* <Button variant="primary" onClick={() => handleSeeMore(item.feature, item.descriptions, item.title, item)} style={{ margin: '10px' }}>See More</Button> */}
                <PaymentComponent classes={item} bookId={item._id} userId={userMeta?.data} />
              </div>
            ))}
          </div>
        ))}
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedClassTitle}&nbsp; &nbsp; ({selectedClassItems.duration})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul style={{ listStyle: 'none', padding: 0, display: 'flex', justifyContent: 'space-between' }}>
            {selectedClassFeature?.map((feature, index) => (
              <li key={index} style={{ flex: '1' }}>
                &bull; {feature}
              </li>
            ))}
          </ul>
          <h4 style={{ textAlign: 'center', textDecoration: 'underline', marginTop: '20px' }}>What your child learns</h4>
          <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            {selectedClassDescription?.map((description, index) => (
              <div key={index} style={{ width: '30%', margin: '10px', textAlign: 'center' }}>
                <h5 style={{ fontWeight: 'bold', marginBottom: '10px' }}>&bull;{description.heading}</h5>
                <p>{description.description}</p>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
          <PaymentComponent classes={selectedClassItems} bookId={selectedClassItems._id} userId={userMeta?.data} />
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EntrepreneurshipClassesFetched;
