import './booking.css';
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Col, Container, Row } from "react-bootstrap";
import MyModal from '../calender/MyModal';
const BookingClasses=()=>{
    const [allTodayClass,setAllTodayClass]=useState([]);
    const [allUpcomingClass,setAllUpcoimgClass]=useState([]);
    const [allPastClass,setAllPostClass]=useState([]);
    const [modalShow, setModalShow] = useState(false);
    const userMeta=JSON.parse(localStorage.getItem('master-app'));

    const handleBookButtonClick = (classId) => {
      setModalShow((prevModalShow) => ({
        ...prevModalShow,
        [classId]: true,
      }));
    };

    const handleModalHide = (classId) => {
      setModalShow((prevModalShow) => ({
        ...prevModalShow,
        [classId]: false,
      }));
    }

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    console.log('Param: ', queryParams.get('pagename'))
    const page = queryParams.get('pagename');
    useEffect(() => {
        const fetchData = async () => {
          try {
            if (userMeta?.data !== undefined) {
              // Fetch today classes
              const todayResponse = await axios.get('https://www.creativekidsworld.org/masterclass/todayclass', {
                params: { userId: userMeta?.data },
              });
              console.log("TodayClasses: ", todayResponse.data.data);
              setAllTodayClass(todayResponse.data.data);
      
              // Fetch upcoming classes only after today classes are fetched
              const upcomingResponse = await axios.get('https://www.creativekidsworld.org/masterclass/upcomingclass', {
                params: { userId: userMeta?.data },
              });
              console.log("Upcoming: ", upcomingResponse.data.data);
              setAllUpcoimgClass(upcomingResponse.data.data);

              const resp = await axios.get('https://www.creativekidsworld.org/masterclass/pastclass',{
                params: {userId:userMeta?.data},
              });
              console.log("PastClasses: ",resp.data.data);
              setAllPostClass(resp.data.data);
    
            }
          } catch (error) {
            console.log("Error: ", error);
          }
        };
      
        fetchData();
      }, []);
      

   
    return(
        <>
        <Header pagename={'dashboard'}></Header>
              <Container>
      <h1 style={{ textAlign: 'center' }}>Classes For Today</h1>
      <Row style={{ margin: "20px 10px" }}>
        {allTodayClass.map((item) => (
          <Col lg={4} key={item.data.id}>
            {/* Ensure to add a unique key for each element in the map */}
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={item.data.image} />
              <Card.Body>
                <Card.Title>{item.data.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.data.timeSlot}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{item.date}</Card.Subtitle>
                <Card.Text className="desc">{item.data.description}</Card.Text>
                <a style={{ cursor: 'pointer' }} onClick={() => handleBookButtonClick(item._id)} className="see-more-button">
                            See More
                          </a>
                          <MyModal title={item.data.title} dataToDisplay={item.data.description} bookId={item._id} userId={userMeta?.data}
                              classes={item.data} bookpage={true}
                              show={modalShow && modalShow[item._id]} onHide={() => handleModalHide(item._id)} />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <h1 style={{ textAlign: 'center' }}>Upcoming Classes</h1>
      <Row style={{ margin: "20px 10px" }}>
        {allUpcomingClass.map((item) => (
        
          <Col lg={4} key={item.data.id}>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={item.data.image} />
              <Card.Body>
                <Card.Title>{item.data.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.data.timeSlot}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{item.date}</Card.Subtitle>
                <Card.Text className="desc">{item.data.description}</Card.Text>
                <a style={{ cursor: 'pointer' }} onClick={() => handleBookButtonClick(item._id)} className="see-more-button">
                            See More
                          </a>
                          <MyModal title={item.data.title} dataToDisplay={item.data.description} bookId={item._id} userId={userMeta?.data}
                              classes={item.data} bookpage={true}
                              show={modalShow && modalShow[item._id]} onHide={() => handleModalHide(item._id)} />

              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <h1 style={{ textAlign: 'center' }}>Past Classes</h1>
      <Row style={{ margin: "20px 10px" }}>
        {allPastClass.map((item) => (
          <Col lg={4} key={item.data.id}>
            {/* Ensure to add a unique key for each element in the map */}
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={item.data.image} />
              <Card.Body>
                <Card.Title>{item.data.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">{item.data.timeSlot}</Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">{item.date}</Card.Subtitle>
                <Card.Text className="desc">{item.data.description}</Card.Text>
                <a style={{ cursor: 'pointer' }} onClick={() => handleBookButtonClick(item._id)} className="see-more-button">
                            See More
                          </a>
                          <MyModal title={item.data.title} dataToDisplay={item.data.description} bookId={item._id} userId={userMeta?.data}
                              classes={item.data} bookpage={true}
                              show={modalShow && modalShow[item._id]} onHide={() => handleModalHide(item._id)} />

              </Card.Body>
             
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
       {/* <UpcominClasses className="components" pagename={page} /> */}
       {/* <PastClasses className="components" pagename={page} /> */}
       <Footer />
        </>
    )
}

export default BookingClasses;