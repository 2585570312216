import './calendar.css';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import MyModal from './MyModal';
import PaymentComponent from '../razorpay/payment';
import { useLocation } from 'react-router-dom';


function Calendar(params) {

  console.log("Page name: ", params);
  const [allData, setAllData] = useState(null);
  const [error, setError] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [month, setMonth] = useState(false);

  const [next10Days, setNext10Days] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  console.log('Param: ', queryParams.get('id'))
  const userId = queryParams.get('id');

  const userMeta = JSON.parse(localStorage.getItem('master-app'));

  const handleBookButtonClick = (classId) => {
    setModalShow((prevModalShow) => ({
      ...prevModalShow,
      [classId]: true,
    }));
  };

  const handleModalHide = (classId) => {
    setModalShow((prevModalShow) => ({
      ...prevModalShow,
      [classId]: false,
    }));
  };

  const scrollToElement = (id) => {

    setTimeout(() => {
      const element = document.getElementById(id);
      // console.log(element);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }
    }, 500);
  };
  useEffect(() => {
    const currentDate = new Date();
    const newNext10Days = Array.from({ length: 7 }, (_, index) => {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + index++);
      return nextDate.toISOString().split('T')[0];
    });

    setNext10Days(newNext10Days);
    const cDate = new Date();
    const day1 = cDate.getDate().toString().padStart(2, '0');
    const month1 = (cDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
    const year1 = cDate.getFullYear();
    const defaultdate = `${day1}-${month1}-${year1}`;

    scrollToElement(defaultdate);
    // handleButtonClick(defaultdate);
    getAllDatas();
  }, []);

  const getAllDatas = async () => {
    try {
      if (params?.pagename === 'story') {
        const resp = await axios.get('https://www.creativekidsworld.org/masterclass/allstory');
        console.log(resp.data['data']);
  
        setAllData(resp.data['data']);
      } else if (params?.pagename === 'innovation') {
        const resp = await axios.get('https://www.creativekidsworld.org/masterclass/getInnovations');
        console.log(resp.data['data']);
  
        setAllData(resp.data['data']);
      } else {
        const resp = await axios.get('https://www.creativekidsworld.org/masterclass/getallclass');
        console.log(resp.data['data']);
  
        setAllData(resp.data['data']);
      }
    } catch (error) {
      setError(error);
    }
  }
  

  const handleButtonClick = async (d) => {
    // console.log(d);
    scrollToElement(d);
  };

  const dates = next10Days.map((date, i) => {
    const currentDate = new Date(date);
    const options = { month: 'short' };
    const threeCharDate = currentDate.toLocaleDateString('en-US', options);
   
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // Note: Months are zero-based
    const year = currentDate.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    return (
      <React.Fragment key={i}>
        {/* <div className='cols' >
          <p>{new Date(date).toLocaleDateString('en-US', { weekday: 'short' })}</p>
          <Link to={formattedDate} smooth={true} offset={-100} duration={500}>
            <h5 onClick={() => handleButtonClick(formattedDate)}>{currentDate.getDate()}</h5>
          </Link>
          <p>{threeCharDate}</p>
        </div> */}

        <Col xs={6} md={3} style={{marginBottom: '12px'}}>
              {/* Your content for the second column, first sub-column */}
              <div className='content'>
                <h6>{new Date(date).toLocaleDateString('en-US', { weekday: 'short' })}</h6>
               <hr/> 
               <span>
               <Link to={formattedDate} smooth={true} offset={-100} duration={500}>
            <h1  style={{    textAlign: 'center',
    marginTop: '32%',cursor: 'pointer'}} onClick={() => handleButtonClick(formattedDate)}>{currentDate.getDate()}</h1>
          </Link>
                {/* <h1>13</h1> */}
               </span>
              </div>
            </Col>


      </React.Fragment>
    );
  });

  return (
    <>
      {/* <div className="masterClass_banar_content text-center" style={{ width: '600px', marginBottom: '60px', marginLeft: '26%' }}>
        <h1 className='clndr'>Master Classes</h1>
        <p style={{ fontSize: '15px' }}>Discover a range of classes designed to help your child discover and learn. You can select individual Classes or get the pass to experience amazing benefits.</p>
      </div> */}
    <Container>
  <Row>
    <Col lg={7} style={{ lineHeight: '2.3' }}>
      {params?.pagename === 'story' ? 
        <h2 className='top-heading'>
          Storytelling & Integrated Learning Session
        </h2>
        :
        params?.pagename === 'innovation' ? 
        <h2 className='top-heading'>
          Innovation Workshop for 21st Century Kids
        </h2>
        :
        <h2 className='top-heading'>
          Master-Classes
        </h2>
       
      }
       {params?.pagename==='story' ? 
           <div><br /></div>
          :
          params?.pagename === 'innovation' ? 
          <div><br /></div>
          :
          <div><br /></div>
        } 
      {params?.pagename === 'innovation' &&
      <h2 style={{color:'red',fontFamily:'italic'}}>What We Offer</h2>
      }
      
      {params?.pagename === 'story' ? 
        <p style={{ fontSize: '18px' }}>
          Welcome to a fun filled story telling and learning session. We embrace creativity to unlock the potential of learning experience for every child. Here, every story becomes a portal to a world of integrated knowledge.
          <br/>What you experience:
        </p>
        :
        params?.pagename === 'innovation' ? 
        <p style={{ fontSize: '18px' }}>
         Are you a young thinker, inventor, or creater? This is your chance to <br/>shine! join our 21st century 7- week Workshop program where you:
        </p>
        :
        <p style={{ fontSize: '18px' }}>
          We weave creativity into the fabric of our masterclasses that allows avenues for deep learning, exploration, and self-expression.
        </p>
      }
      {params?.pagename === 'story' ? 
        <ul>
          <li>Engaging Narrative</li>
          <li>Interactive sessions</li>
          <li>Collaborative Explorations</li>
          <li>Holistic Learning</li>
          <li>Unlimited Fun</li>
          <li>Home Assignments</li>
          <p style={{ fontSize: '18px' }}>Requirements</p>  
          <p style={{ fontSize: '18px' }}>   
            - Laptop / Tablet / Phone<br />
            - Good internet connectivity
          </p>
          <Button style={{ marginLeft: '66px', marginTop: '36px' }}>
            Explore classes for this week
          </Button>
        </ul>
        :
        params?.pagename === 'innovation' ? 
        <ul>
          <li>Deep dive into some of the world-changing Innovations</li>
          <li>Get guidance from expert mentors in exploring new ideas and technologies</li>
          <li>Work with peers on collaborative projects to solve real-world challenges </li>
          <li>Learn how businesses are built around innovative products and solution</li>
          <li>Understand value chain and supply chain through real life business models</li>
          <li>Create & own new ips and ideas around existing innovation </li>
          <li>Get internship and live project opportunities with us and other companies </li>
          <h2 style={{ fontSize: '18px' }}>- Workshop Highlight</h2>  
          <p style={{ fontSize: '18px' }}>   
            - Once a week on weekends<br />
            - Duration 2Hour per session <br />
            - Online session over zoom <br />
            - Soft study material provided
          </p>
          <Button style={{ marginLeft: '66px', marginTop: '36px' }}>
            Explore classes for this week
          </Button>
        </ul>
        :
        <ul>
          <li>Understanding New Techs, innovations, the Whys and Hows</li>
          <li>Dynamic & engaging experience over traditional learning</li>
          <li>Logical & analytical skill development, problem-solving techniques </li>
          <li>Peer learning and encouragement of unique perspectives.</li>
          <li>Collaborative Explorations</li>
          <Button style={{ marginLeft: '66px', marginTop: '36px' }}>
            Explore classes for this week
          </Button>
        </ul>
      }
    </Col>
    <Col lg={5}>
      {params?.pagename === 'story' ? 
        <img src="poster_forest.jpg" height={"485px"} width={"100%"} alt="" />
        :
        params?.pagename === 'innovation' ?
        <img src="/ok.png" height={"485px"} width={"100%"} alt="" />
        :
        <img src="/masterclass2.jpg" height={"485px"} width={"100%"} alt="" />
      }
    </Col>
    <Col lg={12}>
  {/* {params?.pagename === 'innovation' &&
    <img src="/image 1 for innovation.jpeg" height={"485px"} width={"100%"} alt="" />
  }
</Col>
    <Col lg={12}>
      {params?.pagename === 'innovation' &&
        <img src="/image 2 for innovation.jpeg" height={"485px"} width={"100%"} alt="" />
      } */}
    </Col>
  </Row>
</Container>

      <div className='' style={{
        width: '80%',
        marginLeft: '11%',
        marginTop: '36px'
      }}>
        {/* <Card style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px', padding: '10px', marginBottom: '40px' }}>
          <Container>
            <Row>
              <Col lg={12}>
                <div className='rows'>
                  {dates}
                </div>
              </Col>
            </Row>
          </Container>
        </Card> */}

        {/* <div className='main' style={{position:"relative"}}>
        <img src="card-back.png" width={"100%"}  height={'710px'} style={{position:'relative'}} alt="" />
            <div className='head'>
            <h1>Heading</h1>
                  <img src="BLOCK.png" height={'50px'} width={"232px"} alt="" />
            </div>
            <div style={{    position: 'absolute',
             top: '120px',
             left: '15%',width: '75%',width: '75%'}}>
             <Row >
                <Col lg={4} md={3} sm={2}>
                  <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                </Col>
                <Col lg={4} md={3} sm={2}>
                <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                 
                </Col>
                <Col lg={4} md={3} sm={2}>
                <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                  
                </Col>
              </Row>


              <Row >
                <Col lg={4} md={3} sm={2}>
                  <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                </Col>
                <Col lg={4} md={3} sm={2}>
                <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                 
                </Col>
                <Col lg={4} md={3} sm={2}>
                <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                  
                </Col>
              </Row>

              <Row >
                <Col lg={4} md={3} sm={2}>
                </Col>
                <Col lg={4} md={3} sm={2}>
                <img style={{height: '180px'}} src="DATEBLOCK.png" alt="" />
                 
                </Col>
                <Col lg={4} md={3} sm={2}>
                  
                </Col>
              </Row>

            </div>
           
              

        </div> */}

<div style={{    padding: '22px',
    background: 'pink'}}>
<Container fluid>
      <Row>
        {/* First Column (100% width) */}
        <Col style={{marginBottom: '12px'}} xs={12} md={12} lg={3}>
          {/* Your content for the first column */}
          <div style={{ background: 'pink', height: '200px' }}>
            {/* {
           
             
              ;
            } */}
            <h1 className='month'>{new Date( next10Days[0]).toLocaleDateString('en-US', { month: 'short' })}</h1>
            <h6 style={{color: 'white',
    fontSize: '40px',
    textAlign: 'center'}}>WEEK____</h6>
          </div>
        </Col>

        {/* Second Column (Four equal-width columns) */}
        <Col xs={12} md={12} lg={9}>
          <Row>
            <Col xs={6} md={3}>
              {/* Your content for the second column, first sub-column */}
              <div className='content'>
               
                <h1 style={{paddingTop: '30%',
    /* padding-left: 26%; */
    textAlign: 'center',
    color: 'lightcoral',fontFamily: 'cursive'}}>Weekly Calender</h1>
             
              </div>
            </Col>
            {dates}
          </Row>

          {/* <Row style={{marginTop:'14px'}}>
          <Col xs={6} md={3}>
 
              <div className='content'>
                <h6>Sub-column 5</h6>
               <hr/> 
               <span>
                <h1>13</h1>
               </span>
              </div>
            </Col>
            <Col xs={6} md={3}>
      
              <div className='content'>
                <h6>Sub-column 6</h6>
               <hr/> 
               <span>
                <h1>13</h1>
               </span>
              </div>
            </Col>
            <Col xs={6} md={3}>

              <div className='content'>
                <h6>Sub-column 7</h6>
               <hr/> 
               <span>
                <h1>13</h1>
               </span>
              </div>
            </Col>
            <Col xs={6} md={3}>
      
              <div className='content'>
                <h6>Sub-column 8</h6>
               <hr/> 
               <span>
                <h1>13</h1>
               </span>
              </div>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </Container>
</div>
       


        <Container style={{ marginTop: '40px' }}>

          <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>All Classes</h1>

          {allData?.map((classItem) => (
            <Row>

              <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>{moment(moment(classItem.date, 'DD-MM-YYYY').format('MM-DD-YYYY')).format('LL')}</h2>
              <div style={{ display: 'flex' }}>
                {classItem.classDetail?.map((item) => (
                  <Col lg={4} key={classItem.id} style={{ marginBottom: "100px" }}>
                    <div className='flip-ard' id={classItem.date} >
                      <Card key={item.id} className='flip-card-inner' style={{ width: '18rem' }}>
                        <div className='flip-card-front'></div>
                        {/* src={item.image} */}
                        <Card.Img variant="top" src={item.image} height={'200px'} width={"200px"} />
                        <Card.Body>
                          <Card.Title className='capitalize-first-letter' style={{ fontSize: '30px' }}>{item.title}</Card.Title>
                          <Card.Text style={{ fontSize: '18px' }}>{item.timeSlot}</Card.Text>
                          <div className='desc'>
                            {item.description}

                          </div>
                          <a style={{ cursor: 'pointer' }} onClick={() => handleBookButtonClick(item._id)} className="see-more-button">
                            See More
                          </a>
                          <div>
                            < PaymentComponent
                              classes={item}
                              bookId={item._id} userId={userMeta?.data} />
                            <div>


                            </div>
                            <MyModal title={item.title} dataToDisplay={item.description} bookId={item._id} userId={userId}
                              classes={item}
                              show={modalShow && modalShow[item._id]} onHide={() => handleModalHide(item._id)} />
                          </div>
                        </Card.Body>
                        <div style={{ overflow: 'scroll' }} className="flip-card-back">

                          <p>{item.description}</p>
                          <Button className='toggle-btn btnsji' variant="primary"></Button>
                        </div>
                      </Card>
                    </div>
                  </Col>
                ))}
              </div>

            </Row>
          ))}
        </Container>
      </div>
    </>
  );
}

export default Calendar;
